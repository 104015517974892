import React, { useState } from 'react'
import VerticalProgressBar from '../vertical-progress-bar/VerticalProgressBar'
import { AiOutlineSearch } from 'react-icons/ai'
import "./campaign-details-section.css"
import { BiCaretLeft } from 'react-icons/bi'
import AddMusicContent from '../add-music-content/AddMusicContent'
import CampaignPreview from '../campaign-preview/CampaignPreview'
import ChoosePlatform from '../choose-platform/ChoosePlatform'
import Finalize from '../finalize/Finalize'

function CampaignDetailsSections({ pageNumber }) {
  const [searchStr, setSearchStr] = useState("");
  return (
    <div className="body show-select-content-type">
      <div className='header-filter-position'>
        {pageNumber === 3 &&
          <div className="header-filter flex justify-between items-center gap1">
            <button className="back-btn"><BiCaretLeft /></button>
                <h3 className="header-heading">Music Blogs</h3>
                <div className="search-bar-container flex items-center gap1">
                  <label htmlFor="search-platforms">
                    <AiOutlineSearch style={{ fontSize: "20px" }} />
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setSearchStr(e.target.value.trim())}
                    name="search-platforms"
                    id="search-platforms"
                    className="search-platform-input flex1"
                    placeholder="What are you looking for ?" />
                </div>
              {/* </div> */}
            <button className="filter-btn">Filter</button>
          </div>
        }
        <div className="body-main">
          {pageNumber !== 2 && <VerticalProgressBar pageNumber={pageNumber} />}
          <div className="main-content flex1">
            {pageNumber === 1 &&< AddMusicContent/>}
            {pageNumber === 2 &&< CampaignPreview/>}
            {pageNumber === 3 && < ChoosePlatform searchStr={ searchStr} />}
            {pageNumber === 4 &&< Finalize/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignDetailsSections