import Faq from "react-faq-component";

const data = {
  // title: "FAQ (How it works)",
  rows: [
    {
      title: " What is this site all about?",
      content: `Hmm...`,
    },
    {
      title: " How does this site work?",
      content:
        "Hmm...",
    },
    {
      title: "  What kind of musicians can use this site?",
      content: `Hmm...`,
    },
    {
      title: " What is this site all about?",
      content: "Hmmm...",
    },
    {
      title: " What is this site all about?",
      content: "Hmmm...",
    },
    {
      title: " What is this site all about?",
      content: "Hmmm...",
    },
  ],
};

const styles = {
  // bgColor: "red",
  rowContentPaddingLeft: "24px",
  arrowColor: "rgba(255, 97, 77, 1)",
}

function Faquestion() {
  return (
    <Faq data={data} styles={styles} />
  )
}

export default Faquestion;