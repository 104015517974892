import React from "react";
import "./dashboard-campaign-preview.css";

function DashboardCampaignPreview({ campaigns }) {
  return (
    <div className="dashboard-campaign-preview">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th></th>
            <th>Campaign</th>
            <th>Type</th>
            <th>Spent</th>
          </tr>
        </thead>
        <tbody>
          {campaigns?.map((campaign, i) => (
            <tr
              className={i % 2 === 0 ? "even" : "odd"}
              key={campaign.campaignId}
            >
              <td>{i + 1}</td>
              <td>
                <img src={campaign.cover_art_url} alt="" />
              </td>
              <td>{campaign.campaign_name}</td>
              <td>
                {campaign.campaign_type
                  ? campaign.campaign_type.toUpperCase()
                  : campaign.campaign_type}
              </td>
              <td>₦ {campaign.amount_to_pay || "--"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DashboardCampaignPreview;
