import { BASE_URL } from "../utils/commons";

export const register = async (data) => {
    try {
        console.log("Data",data);
        const res = await fetch(BASE_URL + "/auth/register", {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
        return await res.json();
    } catch (e) {
        return {message: "Unable to register, Check you network and try again", errors: {network: "Internet error"}};
    }
    
}

export const verifyMail = async ({id,hash,expires,signature}) => {
    try {
        const res = await fetch(`https://app.newbanger.com/auth/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        if (!res.ok) {
            const { message } = await res.json();
            return await {errors: message}
        }
        return await res.json();
    } catch (e) {
        return {message: "Unable to register, Check you network and try again", errors: {network: "Internet error"}};
    }
}

export const resendVerificationMail = async ({id,hash,expires,signature}) => {
    try {
        const res = await fetch(`https://app.newbanger.com/api/auth/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        if (!res.ok) {
            const { message } = await res.json();
            return await {errors: message}
        }
        return await res.json();
    } catch (e) {
        return {message: "Unable to register, Check you network and try again", errors: {network: "Internet error"}};
    }
}

export const login = async (data) => {
    try {
        const res = await fetch(BASE_URL + "/auth/login", {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
        return await res.json();
    } catch (e) {
        return {message: "Unable to Login, Check you network and try again", errors: {network: "Internet error"}};
    }
    
}

export const getUser = async (token) => {
    try {
        const res = await fetch(BASE_URL + "/user", {
            method: 'get',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        const { data } = await res.json();
        return data || { errors: "unable to fetch user" };
    } catch (e) {
        return {message: "Unable to Login, Check you network and try again", errors: {network: "Internet error"}};
    }
    
}

export const forgotPassword = async (data) => {
    try {
        const res = await fetch(BASE_URL + "/auth/forgot-password", {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
        return await res.json();
    } catch (error) {
        return {message: "Unable to connect, Check you network and try again", errors: {network: "Unable to connect, Check you network and try again"}};
    }
    
}

export const resetPassword = async (data) => {
    try {
        const res = await fetch(BASE_URL + "/auth/change-password", {
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(data)
        })
        return await res.json();
    } catch (error) {
        return {message: "Unable to connect, Check you network and try again", errors: {network: "Unable to connect, Check you network and try again"}};
    }
    
}

export const logout = async () => {
    const token = localStorage.getItem("token")
    try {
        const res = await fetch(BASE_URL + "/auth/logout", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!res.ok) return {errors: "Unable to logout, try again"}
        localStorage.clear("token");
        return await res.json();
    } catch (error) {
        return {message: "Unable to connect, Check you network and try again", errors: {network: "Unable to connect, Check you network and try again"}};
    }
}