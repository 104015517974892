import React,{useContext, useRef, useState, useEffect} from "react";

import "./add-music-content.css"
import ToggleSwitch from "../../../../components/toggle-switch/ToggleSwitch";
import { RxCaretDown } from "react-icons/rx";
import { CreateCampaignContext } from "../../../../context/Context";
import moment from "moment";
import { BiUpload } from "react-icons/bi";
import { centerErrorAlert } from "../../../../utils/alert";
import ReactQuill from "react-quill";

import 'react-quill/dist/quill.snow.css';

export default function AddMusicContent() {
  const hiddenAudioInput = useRef(null);
  const hiddenCoverartInput = useRef(null);
  const [showContentTypes, setShowContentTypes] = useState(false);
  const { state, dispatch } = useContext(CreateCampaignContext);
  const { campaignDetails } = state
  const uploadMedia = (e,refElement) => {
    e.preventDefault();
    refElement.current.click();
  };
  const validateTime = e => {
    const now = moment(new Date())
    const publishDate = moment(e.target.value)
    const diff = publishDate.diff(now, 'hours');
    if (diff < 24) {
      centerErrorAlert("It takes minimum of 24 hours to publish a campaign");
      return
    }
    dispatch({
      type: "campaignDetails",
      payload: { ...campaignDetails, publish_at: publishDate.format("YYYY-MM-DD HH:mm:ss") }
    });
  }
  const handleInputChange = (file, type) => {
    if (!file?.type.startsWith(type)) return;
    if (file?.type.startsWith("audio")) {
      dispatch({type: "fileupload", payload: {isAudioFile: true, isCoverArt: state.isCoverArt}})
      dispatch({ type: "campaignDetails", payload: { ...campaignDetails, audio: file }})
    }
    else {
      dispatch({type: "fileupload", payload: {isCoverArt: true, isAudioFile: state.isAudioFile}})
      dispatch({type:"campaignDetails",payload:{ ...campaignDetails, cover_art: file }})
    }
    console.log(file)
  }
  const selectContentType = (content) => {
    dispatch({ type: "contentType", payload:  content })
    setShowContentTypes(false);
  }
  const handleShowContentType = () => {
    if (state.isDraft) {
      centerErrorAlert("You cannot change draft content Type")
      return
    }
    setShowContentTypes(!showContentTypes)
  }
  return (
    <div className="add-music-content">
      <div className="select-content-type-container flex items-center gap1">
        <div className="label">Content type</div>
        <button className="content-type-btn flex justify-between items-center" onClick={handleShowContentType}>
          {campaignDetails.content_type === "music" ? "Music": campaignDetails.content_type === "music_video" ? "Music Video":"News/Press Release"}
          <RxCaretDown style={{ fontSize: "20px", lineHeight: "0px" }} />
        </button>
        <ul className={`content-type-dropdown ${(showContentTypes && !state.isDraft) && "show-content-types"}`}>
          <li onClick={() => selectContentType("music")}>Music</li>
          <li onClick={() => selectContentType("music_video")}>Music Video</li>
          <li onClick={() => selectContentType("new_press")}>News/Press Release</li>
        </ul>
      </div>

      <form className="upload-form flex gap direct-column">
        <div className="track-title-row flex direct-column gap1">
          <label htmlFor="track-title">Track Title</label>
          <input
            type="text"
            name="track-title"
            id="track-title"
            value={campaignDetails.track_title}
            onChange={(e) => {
              dispatch({type:"campaignDetails",payload:{ ...campaignDetails, track_title: e.target.value }});
            }}
            className="flex1"
            placeholder="Cocoa Butter"
          />
        </div>
        <div className="artiste-name-row flex direct-column gap1">
          <label htmlFor="artiste-name">Artiste Name</label>
          <input
            type="text"
            name="artiste-name"
            id="artiste-name"
            value={campaignDetails.artist_name}
            onChange={(e) => {
              dispatch({type:"campaignDetails",payload:{ ...campaignDetails, artist_name: e.target.value }});
            }}
            className="flex1"
            placeholder="Day Wale" />
        </div>
        <div className="press-release-row flex direct-column gap1">
          <label htmlFor="press-release">Press Release</label>
          <ReactQuill value={campaignDetails.press_release} onChange={(value) => dispatch({type:"campaignDetails",payload:{ ...campaignDetails, press_release: value }})}/>
        </div>
        {state.campaignDetails.content_type !== "new_press" &&
          <div className="upload-audio-row flex direct-column gap1">
            <span>Upload Audio File</span>
            {state.isAudioFile ?
              <button className="button-upload" onClick={e => uploadMedia(e,hiddenAudioInput)}>
                &#10003;&nbsp;&nbsp;Audio Uploaded
              </button> :
              <button className="button-not-upload" onClick={e => uploadMedia(e,hiddenAudioInput)}>
                <BiUpload style={{ fontSize: "22px", lineHeight: "0px", paddingRight: "2px" }} />Upload Audio
              </button>
            }
            <input
              name="upload-audio"
              type="file"
              accept="audio/*"
              onChange={e => handleInputChange(e.target.files[0], "audio/")}
              ref={hiddenAudioInput}
              style={{ display: 'none' }}
            />
          </div>
        }
        <div className="upload-coverart-row flex direct-column gap1">
          <span>Upload Cover art</span>
          {state.isCoverArt ?
            <button className="button-upload" onClick={e => uploadMedia(e,hiddenCoverartInput)}>
              &#10003;&nbsp;&nbsp;Cover art Uploaded
            </button>:
            <button className="button-not-upload" onClick={e => uploadMedia(e,hiddenCoverartInput)}>
              <BiUpload style={{ fontSize: "22px", lineHeight: "0px", paddingRight:"2px" }}/>Upload Cover Art
            </button>
          }
          <input
            name="upload-coverart"
            type="file"
            accept="image/*"
            onChange={e => handleInputChange(e.target.files[0],"image/")}
            ref={hiddenCoverartInput}
            style={{ display: 'none' }}
          />
        </div>

        <div className="date-row">
          <input
            type="text"
            placeholder="Select Date"
            name="date"
            id="date"
            className="flex1 select-date"
            onChange={validateTime}
            onFocus={(e) => e.target.type = "datetime-local"}
            onBlur={(e) => e.target.type = "text"}
          />
        </div>
        {state.campaignDetails.content_type !== "new_press" &&
          <div className="allow-download-row flex justify-between items-center gap1">
            <label htmlFor="allow-download" className="flex1">Allow Download?</label>
            <ToggleSwitch
              id="allow-download"
              name="allow-download"
              checked={!!campaignDetails.allow_download} onChange={() =>
                dispatch({
                  type: "campaignDetails",
                  payload: {
                    ...campaignDetails, "allow_download": !campaignDetails.allow_download
                  }
                })}
              small
            />
          </div>
        }
        {state.campaignDetails.content_type === "music_video" &&
          <div className="add-link-row flex direct-column gap1">
            <label htmlFor="add-link">Add Link (Optional)</label>
            <input
              type="text"
              name="add-link"
              id="add-link"
              value={campaignDetails.other_link}
              onChange={(e) => {
                dispatch({ type: "campaignDetails", payload: { ...campaignDetails, other_link: e.target.value } });
              }}
              className="flex1"
              placeholder="https://www.xyz.com/zeyEsABz12Wt" />
          </div>
        }
        {/* <div className="terms-agreement-row flex gap1">
          <input type="checkbox" name="terms-agreement" id="terms-agreement" className="" />
          <label htmlFor="terms-agreement">I Ageee to  Newbanger Terms and conditions</label>
        </div> */}
      </form>
    </div>
  )
}