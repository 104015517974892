import React from "react";

import { RxBell } from "react-icons/rx"
import { PiCaretDownBold } from "react-icons/pi"

import logo from "../../../../assets/images/logo-l.png"

import "./influencer-campaign-header.css";

function InfluencerCampaignHeader({ avatar }) {
  return (
    <nav className="influencer-campaign-header">
      <div className="container flex justify-between items-center">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="blogger-profile flex items-center gap">
          <div className="header-btn-container flex items-center gap1">
            <button className="home-btn header-btn active">Home</button>
            <button className="start-campaign-btn header-btn">Start a campaign</button>
            <button className="my-campaigns-btn header-btn">My Campaigns</button>
          </div>

          <span className="bell">
            <RxBell />
          </span>

          {/* <img src={wale} alt="blogger" className="blogger-avatar" /> */}
          <div className="blogger-avatar" style={{ backgroundImage: `url(${avatar})` }}>D</div>

          <h4 className="blogger-name">Day Wale</h4>

          <span className="caret-down">
            <PiCaretDownBold />
          </span>
        </div>
      </div>
    </nav>
  )
}

export default InfluencerCampaignHeader;