import React from "react";
import { Link, useNavigate } from "react-router-dom"

import { MdSpeed } from "react-icons/md"
import { GiMegaphone } from "react-icons/gi"
import { PiSignOutBold } from "react-icons/pi"

import logo from "../../assets/images/logo_I.png"

import "./sidenav.css";
import { useContext } from "react";
import { AuthContext } from "../../context/Context";
import { logout } from "../../apis/auth";
import { centerErrorAlert } from "../../utils/alert";

function Sidenav({ showSideNav }) {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const handleLogout = async() => {
    const res = await logout();
    if (res?.errors) {
      centerErrorAlert("Failed to logout, please try again.")
      return
    } 
    dispatch({ type: "logout", payload: res?.message });
    navigate("/")
  }
  return (
    <aside className={`sidenav ${showSideNav ? "show" : "hide"}`}>
      <div className="sidenav-container flex direct-column">
        <img src={logo} alt="logo" />

        <ul className="navlink-container">
          <li><Link className="flex gap1 items-center" to="/musician-dashboard-overview"><MdSpeed />Overview</Link></li>
          <li><Link className="flex gap1 items-center" to="/campaigns"><GiMegaphone />My Campaigns</Link></li>
          {/* <li><Link className="flex gap1 items-center" to="#"><MdSpeed />List</Link></li> */}
          {/* <li><Link className="flex gap1 items-center" to="#"><MdSpeed />Analytics</Link></li> */}
          {/* <li><Link className="notification-navlink flex gap1 items-center" to="#"><MdSpeed />Notifications <div className="notification-label">3 new</div></Link></li> */}
          <li><Link className="flex gap1 items-center" to="/account-setting"><MdSpeed />Settings</Link></li>
        </ul>

        <button className="signout-btn flex gap1" onClick={handleLogout}>
          Sign Out
          <PiSignOutBold />
        </button>
      </div>
    </aside>
  )
}

export default Sidenav;