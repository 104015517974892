import React, { useReducer } from 'react'
import { InfluencerContext } from '../../context/Context';
import { influencerReducer } from './influencerReducer';

function InfluencerProvider({children}) {
    const initialState = { loading: false, success: false, error: null, user: null,influencer: null, message: "" };
    const [state, dispatch] = useReducer(influencerReducer, initialState);
    return (
        <InfluencerContext.Provider value={{ state, dispatch }}>
            {children}
        </InfluencerContext.Provider> 
  )
}

export default InfluencerProvider