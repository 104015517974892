import React from 'react';

import "./content-request-card.css";

// import image from "../../assets/images/content-request.png"

// under review; new; declined; accepted
// accepted ? submit post link
// new ? accept/decline
// under review ? submitted

export default function ContentRequestCard({ image }) {
    const [showDeclineCampaignPopup, setShowDeclineCampaignPopup] = React.useState(false);
    const [showAcceptCampaignPopup, setShowAcceptCampaignPopup] = React.useState(false);
    const [showSubmitPostLinkPopup, setShowSubmitPostLinkPopup] = React.useState(false);
    const [showPostContentThanksPopup, setShowPostContentThanksPopup] = React.useState(false);
    const [showingAcceptContentPopup, setShowingAcceptContentPopup] = React.useState(0)
    const [requestDeclined, setRequestDeclined] = React.useState(false);
    const [requestAccepted, setRequestAccepted] = React.useState(false);
    const [requestUnderReview, setRequestUnderReview] = React.useState(false);
    const codeInputRef = React.useRef(null)

    const submitPostLink = () => {
        setShowSubmitPostLinkPopup(false);
        setRequestUnderReview(true);
        setShowPostContentThanksPopup(true);

        setTimeout(() => {
            setShowPostContentThanksPopup(false);
        }, 5000);
    }

    function copyCode() {
        // Get the text field
        // var copyText = document.getElementById("myInput");

        // Select the text field
        codeInputRef.current.select();
        codeInputRef.current.setSelectionRange(0, 99999); // For mobile devices

        // // Copy the text inside the text field
        navigator.clipboard.writeText(codeInputRef.current.value);
    }

    const code = `<a href="newbanger.com" title=""><img src="" width="100 % " style="max - width: 850px; " alt=""></a><br>Provided by <a href="newbanger.com" target="_blank">louder than you think</a>`;

    return (
        <div className="content-request-card flex direct-column">
            <div className="head flex justify-between">
                <div className="content-type button">MUSIC</div>
                <div className={`content-status button ${requestUnderReview ? "under-review-btn" : ""}`}>{requestDeclined ? "Declined" : requestUnderReview ? "Under Review" : requestAccepted ? "Accepted" : "New"}</div>
            </div>

            <div className="content-type-container">
                <div className="img"><img src={image} alt="content requester" /></div>
                <h3 className="content-title">Music Post Request</h3>
            </div>

            <div className="description">
                <p className="description-text">
                    <span>A Musician want you to post thier music content on your platform</span>
                    <br />
                    <span>post title is Cocoa Butter, He wants the content to go live
                        on 09-04-23</span>
                </p>
            </div>

            {requestAccepted &&
                <button className={`submit-post-link-btn button ${requestUnderReview && "submitted"}`} onClick={() => setShowSubmitPostLinkPopup(true)} disabled={requestUnderReview}>{requestUnderReview ? "Submitted" : "Submit Post Link"}</button>
            }

            {!requestAccepted &&
                <div className="button-container flex items-center">
                    <button className="accept-btn button flex1" disabled={requestDeclined} onClick={() => setShowAcceptCampaignPopup(true)}>Accept</button>
                    <button className="decline-btn button flex1" disabled={requestDeclined} onClick={() => setShowDeclineCampaignPopup(true)}>Decline</button>
                </div>

            }

            {/* POP UPS */}

            {showDeclineCampaignPopup &&
                <div className="decline-campaign-popup">
                    <div className="decline-campaign-card">
                        <p className="popup-card-heading">Are you sure you want to decline posting this content? </p>
                        <div className="button-container flex items-center">
                            <button className="accept-btn button flex1" onClick={() => { setRequestDeclined(true); setShowDeclineCampaignPopup(false) }}>Yes</button>
                            <button className="cancel-btn button flex1" onClick={() => setShowDeclineCampaignPopup(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            }

            {showAcceptCampaignPopup &&
                <div className="accept-campaign-popup">
                    {showingAcceptContentPopup === 0 &&
                        <div className="accept-campaign-card">
                            <p className="popup-card-heading">Are you sure you are ready to post this content? </p>
                            <div className="button-container flex items-center">
                                <button className="accept-btn button flex1" onClick={() => { setShowingAcceptContentPopup(current => current + 1) }}>Yes</button>
                                <button className="cancel-btn button flex1" onClick={() => setShowAcceptCampaignPopup(false)}>Cancel</button>
                            </div>
                        </div>

                    }
                    {showingAcceptContentPopup === 1 &&
                        <div className="accept-campaign-card email-sent-card">
                            <p className="popup-card-heading">We sent you an email </p>
                            <p className="popup-card-text">The email contains the post embed code to be pasted on your website post editor <br />< br /> or you can click the button below  to copy the code</p>
                            <div className="button-container">
                                <button className="accept-btn button flex1" onClick={() => { setShowingAcceptContentPopup(current => current + 1) }}>Copy Embed Code</button>
                            </div>
                        </div>

                    }
                    {showingAcceptContentPopup === 2 &&
                        <div className="copy-embed-code-card">
                            <p className="popup-card-heading">Copy and paste the  Post embed code in your  website post editor</p>
                            <p className="popup-card-text">The content will automatically show when you paste the code into the code editor</p>

                            <div className="code-container">
                                <input type="text" value={code} id="myInput" className="code-input" ref={codeInputRef} readOnly onChange={() => { return; }} />
                                <button onClick={copyCode} className="button copy-code-btn">Copy</button>
                            </div>
                            <p className="popup-card-text">once you copy the code, go to the content page to submit the link or click here to submit  </p>
                            <p className="popup-card-text">if you are having issues  posting the content see our Guide here</p>
                            <div className="">
                                <button className="copy-done-btn button" onClick={() => { setShowingAcceptContentPopup(0); setShowAcceptCampaignPopup(false); setRequestAccepted(true) }}>Done</button>
                            </div>
                        </div>
                    }
                </div>
            }

            {showSubmitPostLinkPopup &&
                <div className="submit-post-link-popup">
                    <div className="submit-post-link-card">
                        <p className="popup-card-heading">KINDLY SUBMIT THE LINK TO THE PUBLISHED CONTENT</p>

                        <div className="form-container">
                            <div className="input-container flex direct-column gap1">
                                <label htmlFor="post-title">Post Title</label>
                                <input type="text" name="post-title" id="post-title" className="flex-1" placeholder="Day Wale- Electrifier Ep" />
                            </div>
                            <div className="input-container flex direct-column gap1">
                                <label htmlFor="post-link">Post Link</label>
                                <input type="text" name="post-link" id="post-link" className="flex-1" placeholder="https://newbanger..com/daywale-electrifier-ep" />
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <button className="accept-btn button" onClick={submitPostLink}>Submit</button>
                        </div>
                    </div>
                </div>
            }

            <div className={`post-content-thanks-card ${showPostContentThanksPopup ? "show" : "hide"}`}>
                <button className="close-thanks-card-btn" onClick={() => setShowPostContentThanksPopup(false)}>X</button>

                <h4 className="head-text">Thanks for posting the content on your platform</h4>
                <p className="paragraph-text">We will review the content and contact you shortly</p>
            </div>
        </div>
    )
}