import React, { useState } from "react";
import Sidenav from "../../../components/sidenav/Sidenav";
import Header from "../../../components/header/Header";
import "./view-campaign.css";
import { BiArrowBack, BiCross } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getCampaign } from "../../../apis/musician";
// import { getCampaign } from "../../../assets/utils/campaigns";

function ViewCampaign() {
  const [showSideNav, setShowSideNav] = useState(true);
  const toggleShowSideNav = () => setShowSideNav((a) => !a);
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    getCampaign(campaignId).then((response) => {
      console.log(response);
      setCampaign(response.data);
    });
  }, [campaignId]);

  return (
    <div className="view-campaign">
      <Sidenav showSideNav={showSideNav} />
      <main className="view-campaign-main">
        <Header
          showSideNav={showSideNav}
          toggleShowSideNav={toggleShowSideNav}
          pageHeading={"My Campaigns"}
        />
        <div className="view-campaign-body">
          <div
            className="campaign-ca-section"
            onClick={() => navigate("/campaigns")}
          >
            <div className="campaign-back">
              <span>
                <BiArrowBack />
              </span>
              <span>Campaign Details</span>
            </div>
            <div className="start-new-campaign">
              <span>
                <BiCross />
              </span>
              <span>Start New Campaign</span>
            </div>
          </div>
          <div className="campaign-details-card">
            <div className="campaign-info">
              <h4>Campaign ID {campaign?.slug}</h4>
              <h4>{campaign?.campaign_type}</h4>
              <h4>Created {campaign?.created_at}</h4>

              {campaign?.campaign_type === "blog" ? (
                <h4>No of Bloggers: {campaign?.bloggers_count}</h4>
              ) : (
                <h4>No of Influncers: {campaign?.influencers_count}</h4>
              )}

              <button>Completed</button>
            </div>
            <div className="campaign-cost">
              <p>Amount</p>
              <p>
                {campaign?.amount_to_pay.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                })}
              </p>
            </div>
          </div>

          {campaign?.campaign_type === "blog" ? (
            <div className="campaign-platforms-table">
              <table>
                <thead>
                  <tr>
                    <th>Platform Name</th>
                    <th>Date Published</th>
                    <th>Views</th>
                    <th>Downloads</th>
                  </tr>
                </thead>
                <tbody>
                  {campaign?.bloggers?.map((platform) => (
                    <tr>
                      <td title={platform.website}>{platform.blog_name}</td>
                      {/* <td>{platform.datePublished}</td> */}
                      <td>'--'</td>
                      <td>{platform.views}</td>
                      <td>{platform.downloads}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="campaign-platforms-table">
              <table>
                <thead>
                  <tr>
                    <th>Platform Name</th>
                    <th>Date Published</th>
                    <th>Views</th>
                    <th>Downloads</th>
                  </tr>
                </thead>
                <tbody>
                  {campaign?.influencers?.map((platform) => (
                    <tr>
                      <td>{platform.influencer_name}</td>
                      {/* <td>{platform.datePublished}</td> */}
                      <td>'--'</td>
                      <td>{platform.views}</td>
                      <td>{platform.downloads}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/*  */}
        </div>
      </main>
    </div>
  );
}

export default ViewCampaign;
