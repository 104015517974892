import React, { useContext, useEffect } from 'react';
import {useNavigate, useParams} from "react-router-dom"
import "./create-blog-campaign.css"
import logo from "../../../assets/images/logo-l.png";
import Header from '../../../components/header/Header';
import SelectCampaignType from './select-campaign-type/SelectCampaignType';
import CampaignDetailsSections from './campaign-details-sections/CampaignDetailsSections';
import { CreateCampaignContext } from '../../../context/Context';
import { SpinningCircles } from 'react-loading-icons';
import { createCampaign, getCampaign, updateCampaign } from '../../../apis/musician';
import { centerErrorAlert } from '../../../utils/alert';

function CreateBlogCampaign() {
  const { state, dispatch } = useContext(CreateCampaignContext);
  const { slug } = useParams();
  const navigate = useNavigate()
  useEffect(() => {
    if (!state.campaignDetails.campaign_type && !slug) {
      navigate("/start-campaign")
    }
    if (slug) {
      dispatch({ type: "loading" })
      fetchCampaign()
    }
  }, [])
  useEffect(() => {
    if (state.pageNumber === 4 && state.selectedPlatforms.length === 0) {
      dispatch({type: "pageNumberChanged",payload: 3})
    }
  },[state])
  const fetchCampaign = async () => {
    try {
      const res = await getCampaign(slug);
      dispatch({ type: "updateCampaignDetails", payload: res.data})
      dispatch({ type: "setDraft", payload: { isDraft: true, pageNumber: 2 } })
      dispatch({type: "fileupload", payload: {isAudioFile: !!res.data.audio_url
, isCoverArt: !!res.data.cover_art_url
}})
    } catch (error) {
      centerErrorAlert("Cannot fetch this campign. Please try again or create new campaign")
    }
    
  }
 
  const checkError = (txt) => {
    dispatch({type: "error", payload: txt})
    centerErrorAlert(state.message || txt)
  }
  
  const pageNumberChanges = async (inc,save) => {
    dispatch({ type: "loading" })
    if (state.isDraft && inc === -1 && state.pageNumber === 1)
      return checkError("Can't change draft content type.");
    if (state.pageNumber === 0 && !state.campaignDetails.content_type)
      return checkError("Please select a Content type to continue")
    if (state.pageNumber === 1 && save && !state.isDraft && !(await saveAsDraft())) return
    if (state.pageNumber === 1 && save && state.isDraft && !(await updateDraft())) return
    if (state.pageNumber === 3 && state.selectedPlatforms.length === 0 && save) 
      return checkError("Please select at least one platform")  
    dispatch({ type: "pageNumberChanged", payload: state.pageNumber + inc })
    console.log(state.pageNumber);
  }
  const retrieveFormData = () => {
    const data = new FormData();
    state.campaignDetails.allow_download = state.campaignDetails.allow_download ? 1 : 0;
    for (const [key, value] of Object.entries(state.campaignDetails)) data.append(key, value);
    return data;
  }
  
  const saveAsDraft = async () => {
    const data = retrieveFormData();
    try {
      const res = await createCampaign(data);
      if (res.data) {
        dispatch({ type: "updateCampaignDetails", payload: res.data })
        navigate(`/create-blog-campaign/${res.data.slug}`)
      }
    } catch (error) {
      dispatch({ type: "error", payload: { errors: error?.errors, message: error.message } })
      centerErrorAlert("Unable to save as draft, please try again!");
    }
    return false
  }
  const updateDraft = async () => {
    const data = retrieveFormData();
    try {
      const res = await updateCampaign(slug, data);
      if (res.data) {
        dispatch({ type: "updateCampaignDetails", payload: res.data })
        return true
      }

    } catch (error) {
      dispatch({ type: "error", payload: { errors: error?.errors, message: error.message } })
      centerErrorAlert("Unable to update draft, please try again!");
    }
    return false
  }
  
  return (
    <div className="create-blog-campaign">
      <Header pageHeading={<img src={logo} alt="logo" />} />
      <main className={`create-blog-campaign-main ${state.pageNumber === 3 && "page-two"}`}>
        {state.pageNumber === 0 && <SelectCampaignType />}
        {state.pageNumber > 0 && <CampaignDetailsSections pageNumber={state.pageNumber} />}
        {!state.loading ?
          <div className="body-footer flex justify-between align-center">
            <button onClick={() => pageNumberChanges(-1)} disabled={state.pageNumber === 0 ? true : false}>Prev</button>
            <button onClick={() => pageNumberChanges(1,true)} disabled={state.pageNumber === 4 ? true : false}>Next</button>
          </div>
            :
          <div className="body-footer-loading flex justify-between align-center">
            <button><SpinningCircles strokeWidth={.1} /></button>
            <button><SpinningCircles strokeWidth={.1}/></button>
          </div>
        }
      </main>
    </div>
  )
}

export default CreateBlogCampaign;