
export const influencerReducer = (state, action) => {
    switch (action.type) {
        case 'error':
            return {...state, loading: false, success: false, error: action.payload};
        case 'loading':
            return { ...state, loading: true, success: false, error: null};
        case 'influencer-complete-signup':
            return { ...state, loading: false, error: null, success: true, blogger: action.payload.data, message: action.payload.message };
        case 'add-socials':
            return {...state, loading: false, error: null, success: true, blogger: null, message: action?.payload?.message}
        default:
            return state;
    }
}