import React from 'react';

import "./button-footer.css";

export default function ButtonFooter() {
  return (
    <div className="button-footer flex items-center justify-between">
      <button className="prev-step-btn button">Previous step </button>
      <button className="next-btn button">Next</button>
    </div>
  )
}