import React, { useEffect, useState,useContext } from 'react'
import { Link } from 'react-router-dom'
import {TbEyeClosed, TbEyeCheck} from 'react-icons/tb';
import {BsFillCheckSquareFill} from 'react-icons/bs';
import "./curator-signup.css";
import WebsiteSidebarL from '../../../../components/website-siderbar-lg/WebsiteSidebarL';
import { register } from '../../../../apis/auth';
import { SpinningCircles } from 'react-loading-icons'
import { AuthContext } from '../../../../context/Context';

function CuratorSignup() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [inputValid, setInputValid] = useState(false);
    const [email, setEmail] = useState("");
    const [typePassword, setTypePassword] = useState(false);
    const[typeConfirmPassword, setTypeConfirmPassword] = useState(false)
    const[password, setPassword] = useState('');
    const[confirmPassword, setConfirmPassword] = useState('');
    const[revealPassword, setRevealPassword] = useState(false);
    const[revealConfirmPassword, setRevealConfirmPassword] = useState(false);
    const [checked, setChecked] = useState(false);
    
    const { state, dispatch } = useContext(AuthContext);
    const checkStrLength = (str) => str.trim().length;
    
    useEffect(() => {
        dispatch({ type: 'reset' })
    }, [dispatch])
    useEffect(() => {
        if(password.length > 0) setTypePassword(true);
        else setTypePassword(false);
    },[password])
    useEffect(() => {
        if(confirmPassword.length > 0) setTypeConfirmPassword(true);
        else setTypeConfirmPassword(false);
    }, [confirmPassword])

    useEffect(() => {
        if (checked && checkStrLength(firstName) > 0 && checkStrLength(lastName) > 0 && checkStrLength(email) > 0 && checkStrLength(password) > 0 && checkStrLength(confirmPassword) > 0) setInputValid(true);
        else setInputValid(false);
    },[firstName,lastName,email,password,confirmPassword,checked])
    
    const handleSignUp = async (e) => {
        e.preventDefault();
        dispatch({ type: "loading" })
        const data = await register({ user_type: "curator", first_name: firstName, last_name: lastName, email, password, password_confirmation: confirmPassword, country_code: "NG" })
        if (data?.errors) dispatch({ type: "error", payload: data.errors })
        else dispatch({ type: "register", payload: data.message })
    }

  return (
    <div className='login-page'>
        <WebsiteSidebarL />
        <section className='login-section'>
            {state.success ?
            <div className="signup-success-container">
                <div className="signup-success-content">
                    <h3>Confirm your email</h3>
                    <p>Just one more thing.We've just sent you an e-mail to confirm your email address. Click the link in the email to confirm your account.</p>
                </div>
            </div>
            :
            <div className="login-section-container">
                <h3>Create a Free Account</h3>
                <p>For Musicians</p>
                <form action="" onSubmit={handleSignUp}>
                    <div className="form-field">
                        <label htmlFor="firstName">First Name</label><br/>
                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='John' name='firstName'/>
                              {state?.error?.first_name ? (state.error.first_name.map((err) => <small key={err}>{ err}<br/></small>)):""}
                        
                    </div>
                    <div className="form-field">
                        <label htmlFor="lastName">Last Name</label><br/>
                              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Doe' name='lastName' />
                              {state?.error?.last_name ? (state.error.last_name.map((err) => <small>{ err}<br/></small>)):""}
                        
                    </div>
                    <div className="form-field">
                        <label htmlFor="email">Email Address</label><br/>
                              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Doe' name='email' />
                              {state?.error?.email ? (state.error.email.map((err) => <small>{ err}<br/></small>)):""}
                        
                        
                    </div>
                    <div className="form-field">
                        <label htmlFor="password">Password</label><br/>
                        <div className="password-input-container">
                                  <input type={revealPassword ? "text" : "password"} value={password} name='password' placeholder='Input your Password' onChange={(e) => setPassword(e.target.value)} />
                                  {state?.error?.password ? (state.error.password.map((err) => <small>{ err}<br/></small>)):""}
                            
                            {typePassword ? 
                                <div className="eyes-icon" onClick={() => setRevealPassword(!revealPassword)}>
                                    {revealPassword ? <span className='eyes-open'><TbEyeCheck /></span>: <span className='eyes-closed'><TbEyeClosed /></span>}
                                </div> : ""
                            }
                                                                                                                              
                        </div>
                    </div>
                    <div className="form-field">
                        <label htmlFor="confirmPassword">Confirm Password</label><br/>
                        <div className="password-input-container">
                                  <input type={revealConfirmPassword ? "text" : "password"} value={confirmPassword} name='password' placeholder='Input your Password' onChange={(e) => setConfirmPassword(e.target.value)} />
                                  {state?.error?.password_confirmation ? (state.error.password_confirmation.map((err) => <small>{ err}<br/></small>)):""}
                            
                            {typeConfirmPassword ? 
                                <div className="eyes-icon" onClick={() => setRevealConfirmPassword(!revealConfirmPassword)}>
                                    {revealConfirmPassword ? <span className='eyes-open'><TbEyeCheck /></span>: <span className='eyes-closed'><TbEyeClosed /></span>}
                                </div> : ""
                            
                            }
                                                                                                                              
                        </div>
                    </div>
                    <div className="checkbox-container" onClick={() => setChecked(!checked)}>
                        {checked ?<span className="checked-box"><BsFillCheckSquareFill /></span>:<span className='unchecked-box'></span>}
                        <span className='checkbox-text'>I Ageee to  Newbanger Terms and conditions</span>
                    </div>
                    
                    <div className="form-field button-submit">
                              {inputValid ? (state.isLoading ? <button><SpinningCircles strokeWidth={.1}/></button> : <input type="submit" value="Submit" />) : <input className='disabled-btn' type="submit" value="Submit" disabled title='click the check button to agree to terms and condition' />}
                              
                    </div>
                    <p className='dont-have-acct'>Already have an account? <Link to="/login" className='link'>Login</Link></p>
                </form>
            </div>
            
            }
        </section>
    </div>
  )
}

export default CuratorSignup
