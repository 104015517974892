import React, { useContext } from "react";

import { RxBell } from "react-icons/rx";
import { PiCaretDownBold } from "react-icons/pi";
import {useNavigate} from "react-router-dom"

import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
// import wale from "../../assets/images/walee.png";

import "./header.css";
import { AuthContext } from "../../context/Context";

function Header({ showSideNav, toggleShowSideNav, pageHeading, avatar }) {
  const { state } = useContext(AuthContext);
  const navigate = useNavigate()
  const user = state.user;
  return (
    <nav className="blogger-welcome-header">
      <div className="container flex justify-between items-center">
        <h1 className="heading" onClick={() => navigate("/musician-dashboard-overview")}>{pageHeading}</h1>

        <span className="mobile-menu">
          <button
            className={`close ${showSideNav ? "show" : "hide"}`}
            onClick={toggleShowSideNav}
          >
            <AiOutlineClose style={{ fontSize: "2em" }} />
          </button>
          <button
            className={`open ${showSideNav ? "hide" : "show"}`}
            onClick={toggleShowSideNav}
          >
            <GiHamburgerMenu style={{ fontSize: "2em" }} />
          </button>
        </span>

        <div className="blogger-profile flex items-center gap">
          <span className="bell">
            <RxBell />
          </span>

          {/* <img src={wale} alt="blogger" className="blogger-avatar" /> */}
          <div className="blogger-avatar" style={{ backgroundImage: `url(${avatar})` }}>{user?.name[0].toUpperCase()}</div>

          <h4 className="blogger-name">{user?.name}</h4>

          <span className="caret-down">
            <PiCaretDownBold />
          </span>
        </div>
      </div>
    </nav>
  );
}

export default Header;
