import React, { useEffect, useState,useContext } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { TbEyeClosed, TbEyeCheck } from 'react-icons/tb';
import { SpinningCircles } from 'react-loading-icons'
import "./login.css";
import WebsiteSidebar from '../../../components/website-sidebar/WebsiteSidebar';
import { AuthContext } from '../../../context/Context';
import { login } from '../../../apis/auth';

function Login() {
    const navigate = useNavigate();
    const [ email, setEmail ] = useState("");
    const [typePassword, setTypePassword] = useState(false);
    const[password, setPassword] = useState('');
    const[revealPassword, setRevealPassword] = useState(false);
    const handlePassword = (e) => setPassword(e.target.value);
    const handleRevealPassword = () => setRevealPassword(!revealPassword);
    const { state, dispatch } = useContext(AuthContext);
    const location = useLocation();

    useEffect(() => {
        dispatch({ type: 'reset' })
    }, [dispatch])

    useEffect(() => {
        if (state?.loggedIn) {
            const returnUrl = new URLSearchParams(location.search).get('returnUrl') || '/';
            navigate(returnUrl)
        } 
    },[state,navigate,location])
    
    useEffect(() => {
        if(password.length > 0) setTypePassword(true);
        else setTypePassword(false);
    }, [password])
    
    

    const handleSubmit = async (e) => {
        e.preventDefault()
        dispatch({ type: 'loading' })
        const data = await login({email, password})
        if (data?.errors) dispatch({ type: 'error', payload: data.errors })
        else {
            localStorage.setItem('token', data.data.token);
            dispatch({ type: 'login', payload: data.data.user });
            if (data.data.user?.user_type === 'musician') navigate("/musician-dashboard-overview");
            else navigate("/curator-complete-signup");
        }
    }
  return (
    <div className='login-page'>
        <WebsiteSidebar />
        <section className='login-section'>
            <div className="login-section-container">
                <h3>Welcome Back</h3>
                <p>We are Happy to see you again !</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <label htmlFor="email">Email Address</label><br/>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='mail@newbanger.com' required />
                        {state?.error?.email ? <small>{state?.error?.email[0]}</small>:""}
                        
                    </div>
                    <div className="form-field">
                        <label htmlFor="password">Password</label><br/>
                        <div className="password-input-container">
                            <input type={revealPassword?"text":"password"} placeholder='Input your Password' onChange={handlePassword} required/>
                            {state?.error?.password ? <small>{state?.error?.password[0]}</small>:""}
                            
                            {typePassword ? 
                                <div className="eyes-icon" onClick={handleRevealPassword}>
                                    {revealPassword ? <span className='eyes-open'><TbEyeCheck /></span>: <span className='eyes-closed'><TbEyeClosed /></span>}
                                </div> : ""
                            }
                                                                                                                              
                        </div>
                    </div>
                    <Link className='forgot-password' to={"/forgot-password"}>Forgot Password ?</Link>
                    
                      <div className="form-field button-submit">
                          {state.isLoading ? (<button><SpinningCircles strokeWidth={.1} /></button>) : (<input type="submit" value="Login" />)}
                    </div>
                    <p className='dont-have-acct'>Don't have an account? <Link to="/account-setup" className='link'>Create an Account</Link></p>
                </form>
            </div>
        </section>
    </div>
  )
}

export default Login