import React, { useReducer } from 'react'
import { createCampaignReducer } from './CreateCampaignReducer'
import { CreateCampaignContext } from '../../context/Context';

function CreateCampaignProvider({ children }) {
  const campaignDetails = {
    "campaign_type": "",
    "content_type": "",
    "track_title": "",
    "artist_name": "",
    "press_release": "",
    "audio": null,
    "cover_art": null,
    "allow_download": false,
    "other_link": "",
    "publish_at": "",
  }
  const initialState = { loading: false, pageNumber: 0, campaignDetails, selectedPlatforms: [], isDraft:false, isCoverArt:false, isAudioFile: false, errors: null, isError: false, message: "" }
  const [state, dispatch] = useReducer(createCampaignReducer, initialState);
  return (
    <CreateCampaignContext.Provider value={{state, dispatch}}>
        {children}
    </CreateCampaignContext.Provider>
  )
}

export default CreateCampaignProvider