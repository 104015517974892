import { Link } from "react-router-dom"

import BackgroundImageGrid from "../../../components/background-image-grid/BackgroundImageGrid"
import NavbarHomepage from "../../../components/navbar-homepage/NavbarHomepage"

import bgImage1 from '../../../assets/images/hg-1.png'
import bgImage2 from '../../../assets/images/hg-2.png'
import bgImage3 from '../../../assets/images/hg-6.png'
import bgImage4 from '../../../assets/images/hg-4.png'
import bgImage5 from '../../../assets/images/hg-5.png'
import bgImage6 from '../../../assets/images/hg-3.png'
import mm from '../../../assets/images/mm.png'
import janne from '../../../assets/images/janne.png'
import ole from '../../../assets/images/ole.png'
import ade from '../../../assets/images/ade.png'
import costIcon from '../../../assets/images/cost.png'
import networkIcon from '../../../assets/images/network.png'
import resultIcon from '../../../assets/images/result.png'
import we from '../../../assets/images/we.png'
import vision from '../../../assets/images/vision.png'


import "./about.css"
import FooterHomepage from "../../../components/footer-homepage/FooterHomepage"
import AboutTeamCard from "../../../components/about-team-card/AboutTeamCard"
import HomepageCard from "../../../components/homepage-card/HomepageCard";


function AboutUs() {
  return (
    <div className="about-page">
      <NavbarHomepage />
      <header className="about-header-container">
        <BackgroundImageGrid
          image1={bgImage1}
          image2={bgImage2}
          image3={bgImage3}
          image4={bgImage4}
          image5={bgImage5}
          image6={bgImage6}
        />

        <h2>Music Promotion Just Got <span className="about-header-easier-span">Easier</span></h2>

        <div className="about-header-mobile-image">

          <img src={mm} alt="Alternative mobile" />
        </div>
      </header>

      <section className="about-lightbg-section">
        <div className="about-bg-container">
          <div className="about-bg-image">
            <img src={we} alt="mobile box" />
          </div>
          <div className="about-bg-article">
            <h2>Who We  Are</h2>
            <p>By leveraging the power of social media, Newbanger enables artists to connect with influencers and tastemakers in their genre or niche, and to create content that will resonate with these influential figures and their followers.</p>
            <p>This creates a powerful feedback loop in which artists gain exposure to new fans while influencers are able to monetize their traffic by posting content submitted by the artists.</p>
          </div>
        </div>
      </section>

      <section className="about-darkbg-section">
        <div className="about-bg-container">
          <div className="about-bg-image">
            <img src={vision} alt="company vision" />
          </div>
          <div className="about-bg-article">
            <h2>Our Vision</h2>
            <p>By leveraging the power of social media, Newbanger enables artists to connect with influencers and tastemakers in their genre or niche, and to create content that will resonate with these influential figures and their followers.</p>
            <p>This creates a powerful feedback loop in which artists gain exposure to new fans while influencers are able to monetize their traffic by posting content submitted by the artists.</p>
          </div>
        </div>
      </section>

      <section>
        <div className='homepage-section-container'>
          <h2>What makes Newbanger different?</h2>
          <div className="homepage-card-container">
            <HomepageCard
              img={costIcon}
              heading="Cost-Effective Promotion"
              text="Newbanger provides affordable promotional packages that fit the budgets of up-and-coming musicians."
            />
            <HomepageCard
              img={networkIcon}
              heading="Extensive Network"
              text="Newbanger offers a vast network of music professionals to help promote your music and expand your reach to new audiences."
            />
            <HomepageCard
              img={resultIcon}
              heading="Transparent Results"
              text="Track the effectiveness of your promotional campaigns in real-time with detailed analytics and metrics."
            />
          </div>
        </div>
      </section>

      <section className="about-team-container">
        <BackgroundImageGrid
          image1={bgImage1}
          image2={bgImage2}
          image3={bgImage3}
          image4={bgImage4}
          image5={bgImage5}
          image6={bgImage6}
        />

        <h2>Our Team</h2>
        <p>Alone, we can do so little; together, we can do so much</p>
      </section>

      <section className="about-our-team">
        <div className="about-team-section-container">
          <AboutTeamCard
            image={janne}
            name="Jannelle Akintade"
            post="Founder, CEO"
          />
          <AboutTeamCard
            image={ole}
            name="Olena Ivanov"
            post="Co- Founder, CTO"
          />
          <AboutTeamCard
            image={ade}
            name="Adewale Desmond"
            post="Co- Founder, CPO"
          />
        </div>
      </section>

      <section className="about-cta-container">
        <BackgroundImageGrid
          image1={bgImage1}
          image2={bgImage2}
          image3={bgImage3}
          image4={bgImage4}
          image5={bgImage5}
          image6={bgImage6}
        />

        <h2>Are you a music curator or professional</h2>
        <p>Connect, Collaborate, and Amplify: Join NewBanger's vibrant community of music curators and professionals to unlock endless possibilities for promoting exceptional music.</p>
        <Link to="#">Apply as a curator</Link>
      </section>

      <FooterHomepage />
    </div>


  )
}

export default AboutUs