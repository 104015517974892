import { useNavigate, Link } from "react-router-dom";

import error404 from "../../assets/images/404.png"
import NavbarHomepage from "../../components/navbar-homepage/NavbarHomepage";

import "./not-found.css"

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div id="error-page">
      <NavbarHomepage />
      <div className="error-container">
        <img src={error404} alt="not found" />
        <h1>Page Not Found</h1>
        <p>"Oops! Looks like we've hit a wrong note."</p>
        <Link onClick={() => navigate(-1)}>Take me Back To The Home Page</Link>

      </div>
    </div>
  );
}