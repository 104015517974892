import React from 'react';

import "./add-influencer.css";

// import logo from "../../../assets/images/logo-l.png"
// import Header from "../../../components/header/Header";
// import { PiCaretLeft } from "react-icons/pi";
// import { IoMdSearch } from "react-icons/io";

// import InfluencerCard from "./influencer-card/InfluencerCard";

// import kemi from "../../../assets/images/kemi-lawal.png"
// import FilterPopup from "./filter-popup/FilterPopup";
// import PreviewInfluencers from "./preview-influencers/PreviewInfluencers";
// import ContentCreationBrief from "./content-creation-brief/ContentCreationBrief";
import InfluencerCampaignSetup from "./influencer-campaign-setup/InfluencerCampaignSetup";
// import AddPaymentWallet from "./add-payment-wallet/AddPaymentWallet";

export default function AddInfluencer() {
  return (
    // <div className="add-influencer">
    //   <Header pageHeading={<img src={logo} alt="logo" />} />

    //   <div className="add-influencer-header flex gap1 justify-between items-center flex-wrap">
    //     <div className="caret-icon"><PiCaretLeft /></div>
    //     <p className="social-media-influencer-text">Social Media Influencers</p>
    //     <div className="search-bar-container flex gap1 items-center">
    //       <label htmlFor="search-influencers" className="lens-icon flex items-center justify-center"><IoMdSearch /></label>
    //       <input type="text" name="search-influencers" id="search-influencers" className="search-influencers-input" placeholder="search influencers" />
    //     </div>
    //     <button className="button filter-btn">Filter</button>
    //   </div>

    //   <div className="add-influencer-body">
    //     <FilterPopup />
    //     {/* <InfluencerCard
    //       name="Kemi Lawal"
    //       age={"19"}
    //       sex={"Female"}
    //       location={"Lagos, Nigeria"}
    //       followers={"6"}
    //       level={"Micro"}
    //       img={kemi}
    //     />
    //     <InfluencerCard
    //       name="Joe David"
    //       age={"19"}
    //       sex={"Female"}
    //       location={"Lagos, Nigeria"}
    //       followers={"7"}
    //       level={"Micro"}
    //       img={kemi}
    //     />
    //     <InfluencerCard
    //       name="Chioma David"
    //       age={"19"}
    //       sex={"Female"}
    //       location={"Lagos, Nigeria"}
    //       followers={"500"}
    //       level={"Micro"}
    //       img={kemi}
    //     />
    //     <InfluencerCard
    //       name="Kemi Lawal"
    //       age={"19"}
    //       sex={"Female"}
    //       location={"Lagos, Nigeria"}
    //       followers={"6"}
    //       level={"Micro"}
    //       img={kemi}
    //     />
    //     <InfluencerCard
    //       name="Joe David"
    //       age={"19"}
    //       sex={"Female"}
    //       location={"Lagos, Nigeria"}
    //       followers={"7"}
    //       level={"Micro"}
    //       img={kemi}
    //     />
    //     <InfluencerCard
    //       name="Chioma David"
    //       age={"19"}
    //       sex={"Female"}
    //       location={"Lagos, Nigeria"}
    //       followers={"500"}
    //       level={"Micro"}
    //       img={kemi}
    //     /> */}
    //   </div>

    //   <div className="footer-next-btn-container">
    //     <p className="text">10 Platforms  Suggested <br />for your budget</p>
    //     <button className="button next-btn">Next</button>
    //   </div>
    // </div>
    // <div className="add-influencer">
    //   <AddPaymentWallet />
    // </div>
    // <div className="add-influencer">
    //   <PreviewInfluencers />
    // </div>
    <div className="add-influencer">
      {/* <ContentCreationBrief /> */}
      <InfluencerCampaignSetup />
    </div>
  )
}