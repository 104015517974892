import React from 'react';
import { IoIosCheckmarkCircle } from "react-icons/io"

import "./content-to-share.css"

export default function ContentToShare() {
  return (
    <div className="content-to-share">
      <button className="back-arrow-btn">&larr;</button>
      <h2 className="heading">What do you want influencers to post on their page? </h2>

      <div className="options-container">
        <div className="label-container">
          <input type="radio" name="content-type" id="artwork-press-release" value="artwork-press-release" className="hidden-input" />
          <label className="nocampaign-card" htmlFor="artwork-press-release">
            <div className="container  flex1">
              <h3 className="desc-heading">Artwork/Press Release</h3>
            </div>
            <div className="checkmark-icon-container">
              <IoIosCheckmarkCircle className="checkmark" />
            </div>
          </label>
        </div>
        <div className="label-container">
          <input type="radio" name="content-type" id="freestyle-video" value="freestyle-video" className="hidden-input" />
          <label className="nocampaign-card" htmlFor="freestyle-video">
            <div className="container  flex1">
              <h3 className="desc-heading">Freestyle Video.</h3>
            </div>
            <div className="checkmark-icon-container">
              <IoIosCheckmarkCircle className="checkmark" />
            </div>
          </label>
        </div>
        <div className="label-container">
          <input type="radio" name="content-type" id="music-video" value="music-video" className="hidden-input" />
          <label className="nocampaign-card" htmlFor="music-video">
            <div className="container  flex1">
              <h3 className="desc-heading">Music Video</h3>
            </div>
            <div className="checkmark-icon-container">
              <IoIosCheckmarkCircle className="checkmark" />
            </div>
          </label>
        </div>
        <div className="label-container">
          <input type="radio" name="content-type" id="others" value="others" className="hidden-input" />
          <label className="nocampaign-card" htmlFor="others">
            <div className="container  flex1">
              <h3 className="desc-heading">Others</h3>
            </div>
            <div className="checkmark-icon-container">
              <IoIosCheckmarkCircle className="checkmark" />
            </div>
          </label>
        </div>
      </div>

    </div>
  )
}