import "./image-caption.css"

function ImageCaption({ image, caption }) {
  return (
    <div className="img-caption-container">
      <div className="img">
        <img src={image} alt={caption} />
      </div>
      <div className="caption">
        {caption}
      </div>
    </div>
  )
}

export default ImageCaption