import React from 'react'
import { Link } from 'react-router-dom';
import megaphone from "../../assets/images/megaphone.png";

function NoCampaign() {
  return (
    <div className="nocampaign-card">
        <div className="container">
            <img src={megaphone} alt="megaphone-icon" />
            <h3 className="desc-heading">You dont have any campaign yet </h3>
            <p className="suggestion-text">Create your first Campaign</p>
            <Link to="/start-campaign" className="start-campaign-btn">Start a campaign &nbsp; &rarr;</Link>
        </div>
    </div>
  )
}

export default NoCampaign