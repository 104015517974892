import React, { useEffect, useState } from "react";
import NoCampaign from "../../../components/no-campaign/NoCampaign";

import "./campaigns.css";
import Header from "../../../components/header/Header";
import Sidenav from "../../../components/sidenav/Sidenav";
import { BiSearch } from "react-icons/bi";
import initialCampaigns from "../../../assets/utils/campaigns";
import { useNavigate } from "react-router-dom";
import { getCampaigns } from "../../../apis/musician";

function Campaigns() {
  const [showSideNav, setShowSideNav] = useState(true);
  const toggleShowSideNav = () => setShowSideNav((a) => !a);
  const [campaigns, setCampaigns] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // setCampaigns([...initialCampaigns]);
    getCampaigns().then((response) => {
      setCampaigns(response.data || []);
    });
  }, []);

  const handleCampaignSearch = (str) => {
    setSearchStr(str);
    const filterCampaigns = initialCampaigns.filter((campaign) =>
      campaign.campaign_name.toLowerCase().includes(str.toLowerCase())
    );
    setCampaigns((prev) => filterCampaigns);
  };

  return (
    <div className="campaign">
      <Sidenav showSideNav={showSideNav} />
      <main className="campaign-main">
        <Header
          showSideNav={showSideNav}
          toggleShowSideNav={toggleShowSideNav}
          pageHeading={"My Campaigns"}
        />
        {campaigns.length === 0 && searchStr === "" ? (
          <div className="campaign-body">
            <NoCampaign />
          </div>
        ) : (
          <div className="campaign-list-body">
            <div className="search-filter-campaign-section">
              <div className="search-filter-campaign-section-container">
                <form action="">
                  <input
                    type="text"
                    onChange={(e) => handleCampaignSearch(e.target.value)}
                    placeholder="Search Campaigns"
                  />
                  <span>
                    <BiSearch />
                  </span>
                </form>
                <div className="filter-campaign">
                  <button>Filter</button>
                </div>
                <div className="create-new-campaign">
                  <button onClick={() => navigate("/start-campaign")}>
                    LAUNCH NEW CAMPAIGN
                  </button>
                </div>
              </div>
            </div>
            <div className="campaign-table">
              <table>
                <thead>
                  <tr>
                    <th>Campaign Name</th>
                    <th>Campaign Type</th>
                    <th>Date Created</th>
                    <th>Amount Spent</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns.map((campaign, i) => (
                    <tr
                      className={i % 2 === 0 ? "even" : "odd"}
                      key={campaign.slug}
                    >
                      <td>{campaign.campaign_name}</td>
                      <td>{campaign.campaign_type}</td>
                      <td>{campaign.created_at}</td>
                      <td>
                        {campaign.amount_to_pay
                          ? campaign.amount_to_pay.toLocaleString("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            })
                          : "--"}
                      </td>
                      <td>
                        <button className={campaign.publish_status}>
                          {campaign.publish_status}
                        </button>
                      </td>
                      <td>
                        <button
                          className="action"
                          onClick={() =>
                            navigate(`/create-blog-campaign/${campaign.slug}`)
                          }
                        >
                          View Insight
                        </button>
                        <button
                          className="action"
                          onClick={() =>
                            navigate(`/view-campaign/${campaign.slug}`)
                          }
                        >
                          View Insight
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default Campaigns;
