import React from "react";
import { NavLink } from "react-router-dom";

import { MdSpeed } from "react-icons/md";
import { GiMegaphone } from "react-icons/gi";
import { PiSignOutBold } from "react-icons/pi";

import logo from "../../assets/images/logo_I.png";

import "./blogger-nav.css";

function BloggerNav({ showSideNav }) {
  return (
    <aside
      className={`blogger-welcome-sidenav ${showSideNav ? "show" : "hide"}`}
    >
      <div className="sidenav-container flex direct-column">
        <img src={logo} alt="logo" />

        <ul className="navlink-container">
          {/* <li>
            <NavLink className="flex gap1 items-center" to="/blogger-content">
              <MdSpeed />
              Overview
            </NavLink>
          </li> */}

          <li>
            <NavLink className="flex gap1 items-center" to="/blogger-content">
              <GiMegaphone />
              Contents
            </NavLink>
          </li>

          {/* <li><NavLink className="flex gap1 items-center" to="#"><MdSpeed />Payment</NavLink></li> */}
          {/* <li><NavLink className="flex gap1 items-center" to="#"><MdSpeed />Support</NavLink></li> */}

          <li>
            <NavLink className="flex gap1 items-center" to="/account-setting">
              <MdSpeed />
              Settings
            </NavLink>
          </li>
          <li><NavLink className="flex gap1 items-center" to="/musician-dashboard-overview"><MdSpeed />Overview</NavLink></li>
          <li><NavLink className="flex gap1 items-center" to="/blogger-payment"><MdSpeed />Payment</NavLink></li>
          <li><NavLink className="flex gap1 items-center" to="/blogger-support"><MdSpeed />Support</NavLink></li>
        </ul>

        <button className="signout-btn flex gap1">
          Sign Out
          <PiSignOutBold />
        </button>
      </div>
    </aside>
  );
}

export default BloggerNav;
