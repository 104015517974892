import React from 'react';
import ReactDOM from 'react-dom/client';
import {
 BrowserRouter
} from "react-router-dom";
import './index.css';
import App from './App';
import AuthProvider from './store/auth/AuthProvider';
import BloggerProvider from './store/blogger/BloggerProvider';
import InfluencerProvider from './store/influencer/InfluencerProvider';
import CreateCampaignProvider from './store/create-campaign/CreateCampaignProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <InfluencerProvider>
          <BloggerProvider>
            <CreateCampaignProvider>
              <App />
            </CreateCampaignProvider>
          </BloggerProvider>
        </InfluencerProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

