import React from 'react'
import { FaRegClock } from "react-icons/fa6";
import activityImg from "../../assets/images/activity-img.png"
import "./activity-preview.css"

function ActivityPreview({activity}) {
    return (
        <div className="activity-details">
            <div className="activity-img">
                <img src={activityImg} alt="" />
            </div>
            <div className="activity-info">
                <h4>{activity?.platform}</h4>
                <p>{activity?.activity}</p>
                <p>
                    <span><FaRegClock/></span>
                    <span>{activity?.time}</span>
                </p>
            </div>
        </div>   
  )
}

export default ActivityPreview