import React, { useContext, useState } from "react";
import { RxBell } from "react-icons/rx";
import { PiCaretDownBold } from "react-icons/pi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

import BloggerNav from "../../../components/blogger-nav/BloggerNav";

import PublishedContent from "./published-content/PublishedContent";
import ContentRequest from "./content-request/ContentRequest";
// import Header from '../../../components/header/Header'

import "./blogger-dashboard-content.css";
import { AuthContext } from "../../../context/Context";
import { getCampaigns as bloggerGetCampaign } from "../../../apis/blogger";
import { getCampaigns as influencerGetCampaign } from "../../../apis/influencer";

export default function BloggerDashboardContent() {
  const [contentShowing, setContentShowing] = useState(0); //this is to set whether "Content Request" or "Published content" should show in the body
  const [showSideNav, setShowSideNav] = useState(true);
  const toggleShowSideNav = () => setShowSideNav((a) => !a);

  const { state } = useContext(AuthContext);
  const user = state.user;

  const [campaigns, setCampaigns] = useState(null);

  if (user?.user_type === 'blogger') {
    bloggerGetCampaign().then(response => {
      setCampaigns(response.json);
    })
  } else if (user?.user_type === 'influencer') {
    influencerGetCampaign().then(response => {
      setCampaigns(response.json);
    })
  } else {
    // not authorized 
    console.log('error not authorized');
  }

  return (
    <div className="blogger-dashboard-content">
      <BloggerNav showSideNav={showSideNav} />
      <main className="blogger-dashboard-content-main">
        {/* <Header showSideNav={showSideNav} toggleShowSideNav={toggleShowSideNav} pageHeading={"Overview"} /> */}
        <header className="blogger-dashboard-content-header">
          <div className="header-container">
            <span className="mobile-menu">
              <button
                className={`close ${showSideNav ? "show" : "hide"}`}
                onClick={toggleShowSideNav}
              >
                <AiOutlineClose style={{ fontSize: "2em" }} />
              </button>
              <button
                className={`open ${showSideNav ? "hide" : "show"}`}
                onClick={toggleShowSideNav}
              >
                <GiHamburgerMenu style={{ fontSize: "2em" }} />
              </button>
            </span>

            <div className="main-navbar-content">
              <div className="flex items-center justfiy-between flex1">
                <button
                  className={`new-content-btn header-btn ${
                    !contentShowing && "active"
                  }`}
                  onClick={() => setContentShowing(0)}
                >
                  New contents
                </button>
                <button
                  className={`published-content-btn header-btn ${
                    contentShowing && "active"
                  }`}
                  onClick={() => setContentShowing(1)}
                >
                  Published Contents
                </button>
              </div>

              <div className="blogger-profile flex items-center gap">
                <span className="bell">
                  <RxBell />
                </span>

                <div className="avatar">{user?.name.substr(0, 1)}</div>

                <h4 className="blogger-name">{user?.name || "User"}</h4>

                <span className="caret-down">
                  <PiCaretDownBold />
                </span>
              </div>
            </div>
          </div>
        </header>

        <div className="body">
          {contentShowing ? <PublishedContent /> : <ContentRequest />}
        </div>
      </main>
    </div>
  );
}
