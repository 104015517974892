import React, { useEffect, useState } from 'react'
import {TbEyeClosed, TbEyeCheck} from 'react-icons/tb';
import "./reset-password.css";
import WebsiteSidebar from '../../../components/website-sidebar/WebsiteSidebar';
import { useContext } from 'react';
import { AuthContext } from '../../../context/Context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../../apis/auth';
import { alertSuccessTopRight } from '../../../utils/alert';
import SpinningCircles from 'react-loading-icons/dist/esm/components/spinning-circles';

function ResetPassword() {
    const navigate = useNavigate();
    const [typePassword, setTypePassword] = useState(false);
    const[password, setPassword] = useState('');
    const[confirmPassword, setConfirmPassword] = useState('');
    const[revealPassword, setRevealPassword] = useState(false);
    const { state, dispatch } = useContext(AuthContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { token } = useParams();
    const email = searchParams.get("email")

    const handleRevealPassword = () => setRevealPassword(!revealPassword);

    useEffect(() => {
        if(!email) navigate("/")
        dispatch({ type: 'reset' })
    },[dispatch,email,navigate])
    useEffect(() => {
        if(password.length > 0) setTypePassword(true);
        else setTypePassword(false);
    }, [password])
    const handleSubmit = async (e) => {
        e.preventDefault()
        dispatch({ type: "loading" })
        const data = await resetPassword({ token, password, email })
        if (data.errors) {
            dispatch({ type: "error", payload: data.errors })
            return;
        } 
        dispatch({ type: "reset-password", payload: data.message })
        alertSuccessTopRight(data.message)
        setPassword("")
        setConfirmPassword("");
        navigate("/login");
    }

  return (
    <div className='login-page'>
        <WebsiteSidebar />
        <section className='login-section'>
            <div className="login-section-container">
                <h3>Reset Your Password</h3>
                <p>Enter a new password to reset your account</p>
                <form action="" onSubmit={handleSubmit}>
                    <div className="form-field">
                        <label htmlFor="password">Password</label><br/>
                        <div className="password-input-container">
                            <input type={revealPassword?"text":"password"} value={password} placeholder='Input your Password' onChange={(e) => setPassword(e.target.value)}/>
                              {state.errorr?.password ? (state?.error?.password.map(err => <small>{err }</small>)) :""}
                            
                            {typePassword ? 
                                <div className="eyes-icon" onClick={handleRevealPassword}>
                                    {revealPassword ? <span className='eyes-open'><TbEyeCheck /></span>: <span className='eyes-closed'><TbEyeClosed /></span>}
                                </div> : ""
                            
                            }
                                                                                                                              
                        </div>
                    </div>
                    <div className="form-field">
                        <label htmlFor="password">Confirm Password</label><br/>
                        <div className="password-input-container">
                            <input type={revealPassword?"text":"password"} value={confirmPassword} placeholder='Confirm your Password' onChange={(e) => setConfirmPassword(e.target.value)}/>
                              {state?.error?.token ? (state?.error?.token.map(err => <small>{err}</small>)) : ""}
                              {state?.error?.email ? (state?.error?.email.map(err => <small>{err } Here</small>)) :""}
                            
                            {typePassword ? 
                                <div className="eyes-icon" onClick={handleRevealPassword}>
                                    {revealPassword ? <span className='eyes-open'><TbEyeCheck /></span>: <span className='eyes-closed'><TbEyeClosed /></span>}
                                </div> : ""
                            
                            }
                                                                                                                              
                        </div>
                        
                    </div>
                    
                    <div className={password.trim() === "" ? "form-field button-submit blur":"form-field button-submit"}>
                          {password.trim() === "" ? <input type="submit" value="Retrieve Password" disabled /> :
                              (
                                  state.isLoading ? (<button disabled><SpinningCircles strokeWidth={.1} /></button>) : (<input type="submit" value="Reset Password" />)
                              )
                          }
                        
                    </div> 
                    <ul>
                        <li>Password should contain a uppercas letter, number or a spacial character</li>
                        <li>Password must be at least 8 characters long</li>
                    </ul>
                </form>
            </div>
        </section>
    </div>
  )
}

export default ResetPassword;