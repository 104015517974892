import React from 'react'
import { Link } from 'react-router-dom'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { RiArrowDropDownLine } from 'react-icons/ri'
import Logo from "../../assets/svg/Logo-l.svg";
import "./navbar-dashboard.css";

function NavbarDashboard() {
  return (
    <nav className='dashboard-navigation'>
        <div className="navbar-logo">
            <img src={Logo} alt="Newbanger logo" />
        </div>
        <ul className="list-navigation-items">
            <li className="list-item"><Link className='link active'>Dashboard</Link></li>
            <li className="list-item"><Link className='link'>Publish content</Link></li>
            <li className="list-item"><Link className='link'>Contents</Link></li>
            <li className="list-item user-notification">
                <div className='user-notification-dropdown-icons'><IoMdNotificationsOutline/></div>
                <div className='user-prefix'>D</div>
                <div className='firstname-dropdown'>
                    <span>Vibes</span>
                    <div className='user-notification-dropdown-icons'><RiArrowDropDownLine /></div>
                </div>
            </li>
        </ul>
    </nav>
  )
}

export default NavbarDashboard