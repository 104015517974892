import React, { useState,useContext,useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./forgot-password.css";
import WebsiteSidebar from '../../../components/website-sidebar/WebsiteSidebar';
import { SpinningCircles } from 'react-loading-icons'
import { AuthContext } from '../../../context/Context';
import { forgotPassword } from '../../../apis/auth';
import { alertSuccessTopRight } from '../../../utils/alert';

function ForgotPassword() {
    let [email, setEmail] = useState("");
    const { state, dispatch } = useContext(AuthContext);
    useEffect(() => {
        dispatch({ type: 'reset' })
    },[dispatch])

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        dispatch({ type: "loading" })
        const data = await forgotPassword({ email })
        dispatch({ type: "forget-password", payload: data.message })
        alertSuccessTopRight(data.message)
        setEmail("")
    }
  return (
    <div className='login-page'>
        <WebsiteSidebar />
        <section className='login-section'>
            <div className="login-section-container">
                <h3>Forgot your password?</h3>
                  <p>Enter the email associated with your account and we’ll send a code with<br /> instruction to reset your password.</p>
                  {/* {state.success ? <p>{state.message}</p> : ""} */}
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <label htmlFor="email">Email Address</label><br/>
                        <input type="email" placeholder='mail@newbanger.com' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className={email.trim() === "" ? "form-field button-submit blur":"form-field button-submit"}>
                          {email.trim() === "" ? <input type="submit" value="Retrieve Password" disabled /> :
                              (
                                  state.isLoading ? (<button disabled><SpinningCircles strokeWidth={.1} /></button>) : (<input type="submit" value="Reset Password" />)
                              )
                          }
                        
                    </div>
                    <p className='dont-have-acct'>Remember your password? <Link to="/login" className='link'>Login</Link></p>
                </form>
            </div>
        </section>
    </div>
  )
}

export default ForgotPassword