import "./background-image-grid.css"

function BackgroundImageGrid({image1, image2, image3, image4, image5, image6}) {
  return(
    <>
      <div className="bg-img-grid-container">
        <div className="bg-img-row-span3"><img src={image1}  alt="img"/></div>
        
        <div className="two" ><img src={image2} alt="img"/></div>

        <div className="bg-img-start3-col-span2"><img src={image3}  alt="img"/></div>

        <div className="bg-img-start2-col-span2"><img src={image4}  alt="img"/></div>

        <div className="five"><img src={image5}  alt="img"/></div>

        <div className="six"><img src={image6}  alt="img"/></div>     
      </div>
      <div className="bg-img-overlay"></div>
    
    </>
  )
}

export default BackgroundImageGrid;