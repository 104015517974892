import React from 'react'

import logo from "../../assets/images/logo-l.png"
import sideBarImg from "../../assets/images/sidebar-img-light.png";
import "./website-sidebar-l.css";
import { useNavigate } from 'react-router-dom';

function WebsiteSidebarL() {
  const navigate = useNavigate();
  return (
    <sidebar className="website-sidebar-l">
      <div className='logo-img-l' onClick={() => navigate("/")}>
            <img src={logo} alt="newbanger logo" />
        </div>
        <div className='sidebar-content-l'>
            <div className='selfie-img'><img src={sideBarImg} alt="Selfie for side bar login" /></div>
            <p>Take your music career to the next level, Music promotion just got easier, sign up to promote your song on blogs, Social media, Playlists, radio and more at a lesser cost in a more transparent way</p>
        </div>
    </sidebar>
  )
}

export default WebsiteSidebarL