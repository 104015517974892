import React, { useState } from 'react'
import {AiFillInstagram} from "react-icons/ai";
import { BsTwitter} from "react-icons/bs";
import "./influencer-complete-signup.css"
import { countryList } from '../../../../assets/utils/countries';
import FooterDashboard from '../../../../components/footer-dashboard/FooterDashboard';
import NavbarDashboard from '../../../../components/navbar-dashboard/NavbarDashboard';
import { useNavigate } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa';
import { BiLogoFacebook } from 'react-icons/bi';
import { Bars } from 'react-loading-icons';
import { useContext } from 'react';
import { InfluencerContext } from '../../../../context/Context';
import { influencerAddSocials } from '../../../../apis/influencer';

function InfluencerCompleteSignup() {
  const navigate = useNavigate();
  let [influencerStep, setInfluencerStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  let [selectedMedia, setSelectedMedia] = useState("");
  const[tiktok] = useState("");
  const[facebook] = useState("");
  const[twitter] = useState("");
  const[instagram] = useState("");
    const { state, dispatch } = useContext(InfluencerContext);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/influencer-welcome");
  }
  const handleStepIncrement = () =>{
    setInfluencerStep(++influencerStep);
    }
    
    const linkSocials = async (socials) => {
        dispatch({ type: 'loading' })
        const res = await influencerAddSocials(socials.toLowerCase());
        dispatch({ type: 'add-socials', payload: null })
        window.location.href = res?.data?.redirect_url;
    }

  const handleMedia = (str) => {
    setOpenModal(true);
      setSelectedMedia(str)
      linkSocials(str);
  }

  return (
    <div className='influencer-complete-signup-page'>
        <NavbarDashboard />
        {influencerStep === 1 ? 
            <section className="link-social-media">
                <h3>Link your Social Media Profile</h3>
                <div className="social-media-options">
                    <div className='select-social-media'>
                        <div className='social-media-option'>
                            <h4><FaTiktok/> Tiktok</h4>
                            <button className={tiktok === "" ? 'link-profile': 'link-profile media-url'} onClick={() => handleMedia("Tiktok")}>Link Profile</button>
                        </div>
                        <div className='social-media-option'>
                            <h4><BiLogoFacebook/> Facebook</h4>
                            <button className={facebook === "" ? 'link-profile': 'link-profile media-url'} onClick={() => handleMedia("Facebook")}>Link Profile</button>
                        </div>
                        <div className='social-media-option'>
                            <h4><BsTwitter/> Twitter</h4>
                            <button className={twitter === "" ? 'link-profile': 'link-profile media-url'} onClick={() => handleMedia("Twitter")}>Link Profile</button>
                        </div>
                        <div className='social-media-option'>
                            <h4><AiFillInstagram/> Instagram</h4>
                            <button className={instagram === "" ? 'link-profile': 'link-profile media-url'} onClick={() => handleMedia("Instagram")}>Link Profile</button>
                        </div>
                    </div>
                    {openModal?
                        <>
                              <div className="social-media-link-modal">
                                  {!state.loading && <h2 onClick={() => setOpenModal(false)}>X</h2>}
                                
                                <div className="modal-content">
                                      <h3>{state.loading ? `Linking ${selectedMedia}` : "Failed!!!"}</h3>
                                      <p>{state.loading ? "Please wait while the sytem redirect you..." : "Close the modal and try again"}</p>
                                      {state.loading && <Bars stroke="#FF614D" fill='#FF614D' height={30} width={400} />}
                                    {/* {selectedMedia.toLowerCase() === "tiktok" ? 
                                        <input type="text" onChange={(e) => setTiktok(e.target.value.trim())} value={tiktok} placeholder={selectedMedia.toLowerCase() + ' username'} />:""}
                                    {selectedMedia.toLowerCase() === "facebook" ? 
                                        <input type="text" onChange={(e) => setFacebook(e.target.value.trim())} value={facebook} placeholder={selectedMedia.toLowerCase() + ' username'} />:""}
                                    {selectedMedia.toLowerCase() === "twitter" ? 
                                        <input type="text" onChange={(e) => setTwitter(e.target.value.trim())} value={twitter} placeholder={selectedMedia.toLowerCase() + ' username'} />:""}
                                    {selectedMedia.toLowerCase() === "instagram" ? 
                                        <input type="text" onChange={(e) => setInstagram(e.target.value.trim())} value={instagram} placeholder={selectedMedia.toLowerCase() + ' username'} />:""} */}
                                    
                                      {!state.loading && <button onClick={() => setOpenModal(false)}>Close</button>}
                                </div>
                            </div>
                            <div className="modal-background"></div>
                        </>:""
                    }
                    
                    <div className="continue-previous-btns">
                        <button>Previous</button>
                        {tiktok||facebook||twitter||instagram ? 
                            <button className='selected-option media-url' onClick={handleStepIncrement}>Select</button>:
                            <button className='selected-option' disabled>Select</button>
                        }
                    </div>
                </div>
            </section> :""
        }
        {influencerStep === 2?
            <section className="biodata">
                <div className="blogger-input-form-section-1">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-field">
                            <label htmlFor="email">Gender</label><br/>
                            <select name="" id="">
                                <option value="">Select range</option>
                                <option value="Entertainment">Male</option>
                                <option value="Gospel">Female</option>
                                <option value="Sport">Others</option>
                            </select>
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">Country</label><br/>
                            <select name="" id="">
                                <option value="">--Select country from dropdown</option>
                                {countryList.map(country => <option value={country.country_code} key={country.country_code}>{country.name}</option>)}
                            </select>
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">Country</label><br/>
                            <select name="" id="">
                                <option value="">--Select country from dropdown</option>
                                {countryList.map(country => <option value={country.country_code} key={country.country_code}>{country.name}</option>)}
                            </select>
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">Country</label><br/>
                            <input type="date" />
                        </div>
                        <div className="form-field">
                            <input type="submit" value="Complete"/>
                        </div>
                    </form>
                </div>
            </section>:""
        } 
        <FooterDashboard />
    </div>
  )
}

export default InfluencerCompleteSignup