import React, { useState } from 'react';

import "./blogger-payment.css";
import BloggerNav from '../../../components/blogger-nav/BloggerNav';
// import Header from '../../../components/header/Header';
import PaymentHeader from '../../../components/payment-header/PaymentHeader';
// import RevenueDefault from './revenue-default/RevenueDefault';
// import AddBank from './add-bank/AddBank';
// import AddBankConfirmation from "./add-bank-confirmation/AddBankConfirmation";
// import PaymentWallet from "./payment-wallet/PaymentWallet";
import WithdrawFund from "./withdraw-fund/WithdrawFund";

export default function BloggerPayment() {
  const [showSideNav, setShowSideNav] = useState(true)
  const toggleShowSideNav = () => setShowSideNav(a => !a);

  return (
    <div className="blogger-payment">
      <BloggerNav showSideNav={showSideNav} />

      <main className="blogger-payment-main">
        <PaymentHeader showSideNav={showSideNav} toggleShowSideNav={toggleShowSideNav} />

        <div className="body">
          <WithdrawFund />
        </div>
      </main>
    </div>
  )
}