import React, { useContext } from 'react'
import { IoIosCheckmarkCircle } from "react-icons/io"
import { CreateCampaignContext } from '../../../../context/Context'

function SelectCampaignType() {
  const {state, dispatch } = useContext(CreateCampaignContext);
  return (
    <div>
      <div className={`select-content-type ${"hide-select-content-type"}`}>
          <div>
            <button className="back">
              <span style={{ fontSize: "50px", lineHeight: "0px" }}>&larr;</span>
            </button>
            <h2 className="heading">Select Your Content Type</h2>

            <div className="form-main-container">
              <div className="label-container">
              <input type="radio" name="content-type" id="music" onChange={() => dispatch({type:"contentType",payload:"music"}) } checked={state.campaignDetails.content_type === "music"}/>
                <label className="nocampaign-card" htmlFor="music">
                  <div className="container">
                    <h3 className="desc-heading">Music</h3>
                    <p className="suggestion-text">boost your online presence, Get your song and other contents published on influential music blogs</p>
                    <div className="checkmark-icon-container">
                      <IoIosCheckmarkCircle className="checkmark" />
                    </div>
                  </div>
                </label>
              </div>
              <div className="label-container">
                <input type="radio" name="content-type" id="music-video" onChange={() => dispatch({type:"contentType",payload:"music_video"}) } checked={state.campaignDetails.content_type === "music_video"}/>
                <label className="nocampaign-card" htmlFor="music-video">
                  <div className="container">
                    <h3 className="desc-heading">Music Video</h3>
                    <p className="suggestion-text">Make Your music go viral by working with influencers on Facebook,Tiktok,twitter and Instagram</p>
                    <div className="checkmark-icon-container">
                      <IoIosCheckmarkCircle className="checkmark" />
                    </div>
                  </div>
                </label>
              </div>
              <div className="label-container">
                <input type="radio" name="content-type" id="news" onChange={() => dispatch({type:"contentType",payload:"new_press"}) } checked={state.campaignDetails.content_type === "new_press"}/>
                <label className="nocampaign-card" htmlFor="news">
                  <div className="container">
                    <h3 className="desc-heading">News/Press Release</h3>
                    <p className="suggestion-text">Avoid wrong placements and fake streams, submit your song to playlists, through our verified playlist curators</p>
                    <div className="checkmark-icon-container">
                      <IoIosCheckmarkCircle className="checkmark" />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default SelectCampaignType