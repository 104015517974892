import React from 'react';

import "./progress-bar.css";

export default function ProgressBar({ stepNumber }) {
  return (
    <div className="progress-bar">
      <div className={`steps flex ${stepNumber === 0 ? "one" : stepNumber === 1 ? "two" : stepNumber === 2 ? "three" : ""}`}>
        <div className="step step-one flex gap items-center">
          <div className="circle"></div>
          <div className="flex direct-column gap1">
            <span className="step-heading">Campaign setup</span>
            <span className="step-number">Step 1</span>
          </div>
        </div>
        <div className="step step-two flex gap items-center">
          <div className="circle"></div>
          <div className="flex direct-column gap1">
            <span className="step-heading">Choose Influencers</span>
            <span className="step-number">Step 2</span>
          </div>
        </div>
        <div className="step step-three flex gap items-center">
          <div className="circle"></div>
          <div className="flex direct-column gap1">
            <span className="step-heading">Campaign setup</span>
            <span className="step-number">Step 3</span>
          </div>
        </div>

        {/* <div className={`animating-progress-container ${stepNumber === 1 ? "two" : stepNumber === 2 ? "three" : ""}`}>
          <div className="animating-bar"></div>
        </div> */}
      </div>
    </div>
  )
}