import React from 'react'
import "./account-setting-edit-basic-info.css";
import dp from "../../assets/images/bio-dp.png";

function AccountSettingEditBasicInfo() {
  return (
      <>
        <div className="basic-info-header">
            <div className="info-header-img">
                <img src={dp} alt="" />
            </div>
            <div className="info-details">
                <h4>Fatzy</h4>
                <p>Musician</p>
                <input type="file" hidden/>
                <button>Change Picture</button>
            </div>
        </div>
        <div className="basic-info-form">
            <form action="">
                <div className="form-field-pair">
                    <div className="form-field">
                        <label htmlFor="firstname">First Name</label>
                        <input type="text" name='firstname' placeholder='Jannelle'/>
                    </div>
                    <div className="form-field">
                        <label htmlFor="lastname">Last Name</label>
                        <input type="text" name='lastname' placeholder='Banger'/>
                    </div>
                </div>
                <div className="form-field-pair">
                    <div className="form-field">
                        <label htmlFor="email">Email</label>
                        <input type="email" name='email' placeholder='janelle@banger.com'/>
                    </div>
                    <div className="form-field">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="phone" name='phone' placeholder='0812*******'/>
                    </div>
                </div>
                <div className="form-field-pair">
                    <div className="form-field">
                        <label htmlFor="Gender">Gender</label>
                        <input type="text" name='Gender' placeholder='Male'/>
                    </div>
                    <div className="form-field">
                        <label htmlFor="dob">Date of Birth</label>
                        <input type="date" name='dob' placeholder='Banger'/>
                    </div>
                </div>
                <div className="line-space"></div>
                <div className='form-line'></div>
                <button type='submit'>Update</button>
            </form>
        </div>
      </>
  )
}

export default AccountSettingEditBasicInfo