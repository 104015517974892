import React from 'react';
import { Link } from 'react-router-dom';
import './email-verification-warning.css';
import { useState } from 'react';
import { BASE_URL } from '../../utils/commons';

function EmailVerfificationWarning() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failedTxt, setFailedTxt] = useState('');
    const resendEmail = async () => {
        setLoading(true);
        let token = localStorage.getItem('token')
        try {
            const res = await fetch(`${BASE_URL}/auth/email/verification-notification`, {
                method: 'post',
                headers: {'Content-Type': 'application/json','Accept': 'application/json', 'Authorization': `Bearer ${token}` }
            })
            if (res.ok) setSuccess(true);
        } catch (error) {
            
        }
        setLoading(false);
        if(!success) setFailedTxt('Failed to resend link, try again')
    }
    return (
        <div className="mail-verification-warning">
            {loading ? 
                <p>Please wait...</p> :
                (
                    success?
                        <p>An email verification link has been sent to your email.</p> :
                        <p>{failedTxt ? failedTxt : "Please verify your mail to continue"}.<Link className='verification-mail-link' onClick={resendEmail}> Resend Verification Link</Link></p>
                )
            
            }
        </div>
    
    )
}

export default EmailVerfificationWarning