import React, { useContext } from "react";
import { FaPlay } from "react-icons/fa6";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import wale from "../../../../assets/images/wale.png";


import "./campaign-preview.css";
import { CreateCampaignContext } from "../../../../context/Context";

export default function CampaignPreview() {
  const { state, dispatch } = useContext(CreateCampaignContext);
  return (
    <div className="campaign-preview">
      <main className="main-container">
        <div className="heading-text-card"><p className="heading-text">Here is a preview of how your post is going to look on the platform you chose</p></div>

        <div className="container">
          <h1>{state.campaignDetails.artist_name} Releases New Single {state.campaignDetails.track_title}</h1>

          <div className="image-container">
            <img src={state.campaignDetails.cover_art_url} alt="cover" />
          </div>

          <div className="body-text flex direct-column gap" dangerouslySetInnerHTML={{__html:state.campaignDetails.press_release}}/>

          <div className="spotify-preview flex gap">
            <div className="image">
              <img src={state.campaignDetails.cover_art_url} alt="cover art" />
            </div>

            <div className="spotify-desc flex1 flex direct-column gap1">
              <span className="title">{state.campaignDetails.track_title}</span>
              <span className="artiste">
                by&nbsp;
                <span className="artiste-name">{state.campaignDetails.artist_name}</span>
              </span>

              <div className="progress-bar-container flex items-center">
                <div className="icon"><FaPlay style={{ fontSize: "20px", lineHeight: "0px" }} /></div>
                &nbsp;
                <span className="time">00:00</span>
                &nbsp;
                <div className="audio-progress"></div>

                <span className="time">00:00</span>
                &nbsp;
                <div className="icon"><HiOutlineSpeakerWave style={{ fontSize: "20px", lineHeight: "0px" }} /></div>
                &nbsp;
                <div className="volume-magnitude"></div>
              </div>
            </div>
          </div>
          {state.campaignDetails.allow_download && <button className="download-audio-btn">DOWNLOAD MP3</button>}
        </div>
      </main>
    </div>
  )
}