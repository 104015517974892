import { fetchAPI } from ".";
import { BASE_URL } from "../utils/commons";

// GET ALL CAMPAIGNS
export const getCampaigns = async () => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

// GET SINGLE CAMPAIGN
export const getCampaign = async (campaignSlug) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}`, {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
}

export const createCampaign = async (data) => {
  const token = localStorage.getItem("token");
  return fetchAPI(BASE_URL + `/campaigns`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: data
  })
}


export const updateCampaign = async (campaignSlug, data) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: data
  })
}


// CAMPAIGN BLOGGERS 
export const getCampaignBloggers = async (campaignSlug) => {
  const token = localStorage.getItem("token");
  console.log("Got here")
  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}/bloggers`, {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
}

export const storeCampaignBloggers = async (campaignSlug, data) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}/bloggers`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  })
}

// CAMPAIGN INFLUENCERS 
export const getCampaignInfluencers = async (campaignSlug, filters) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}/influencers`, {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: { ...filters }
  })
}

export const storeCampaignInfluencers = async (campaignSlug, data) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}/influencers`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: { ...data },
  })
}

// CONFIRM PAYMENT
export const campaignConfirmPayment = async (campaignSlug, data) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}/confirm-payment`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  })
}

// DELETE CAMPAIGN
export const deleteCampaign = async (campaignSlug) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/campaigns/${campaignSlug}`, {
    method: 'delete',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
}