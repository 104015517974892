import { fetchAPI } from ".";
import { BASE_URL } from "../utils/commons";

export const bloggerCompleteSignup = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const res = await fetch(BASE_URL + "/user/blogger", {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: data
    })
    return await res.json();
  } catch (e) {
    return { message: "Unable to connect, Check you network and try again", errors: { network: "Unable to connect, Check you network and try again" } };
  }
}

// GET ALL CAMPAIGNS
export const getCampaigns = async () => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/blogger/campaigns`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

// GET ALL CAMPAIGNS
export const acceptCampaignTask = async (campaignSlug, data) => {
  const token = localStorage.getItem("token");

  return fetchAPI(BASE_URL + `/blogger/campaigns/${campaignSlug}/task/accept`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: { ...data }
  })
}