import React from 'react'
import { BiSolidToggleLeft } from 'react-icons/bi'
import "./notification-type.css";

function NotificationType() {
  return (
    <div className="acoount-setting-switch-notification">
        <div className="notification-type">
            <h4>Login Notification</h4>
            <p><BiSolidToggleLeft/></p>
        </div>
    </div>
  )
}

export default NotificationType