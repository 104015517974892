import React, {useState} from 'react'
// import Sidenav from '../../../components/sidenav/Sidenav'
import Header from '../../../components/header/Header';
import "./account-setting.css";
import AccountSettingEditBasicInfo from '../../../components/account-settings-edit-basic-info/AccountSettingEditBasicInfo';
// import { BiSolidToggleLeft } from "react-icons/bi";
import AccountSettingEditPassord from '../../../components/account-setting-edit-password/AccountSettingEditPassord';
import NotificationType from '../../../components/notification-type/NotificationType';
import BloggerNav from "../../../components/blogger-nav/BloggerNav";

function AccountSetting() {
    const [showSideNav, setShowSideNav] = useState(true)
    const [selectSetting, setSelectSetting] = useState(0);
    const toggleShowSideNav = () => setShowSideNav(a => !a);
    const settingList = ["Basic Information","Login/Security","Privacy/sharing"]
   return (
      <div className='account-setting'>
          <BloggerNav showSideNav={showSideNav} />
          <main className="account-setting-main">
              <Header showSideNav={showSideNav} toggleShowSideNav={toggleShowSideNav} pageHeading={"Settings"} />
              <div className="account-setting-body">
                  <div className="account-setting-selector">
                     <h5 className={selectSetting === 0 ?'active-info':""} onClick={() => setSelectSetting(0)}>{settingList[0]}</h5> 
                       <h5 className={selectSetting === 1 ?'active-info':""} onClick={() => setSelectSetting(1)}>{settingList[1]}</h5> 
                       <h5 className={selectSetting === 2 ?'active-info':""} onClick={() => setSelectSetting(2)}>{settingList[2]}</h5> 
                  </div>
                  <div className="account-setting-edit-details">
                      <h3>{settingList[selectSetting]}</h3>
                      <div className='form-line'></div>
                       {selectSetting === 0 && <AccountSettingEditBasicInfo />}
                       {selectSetting === 1 && <AccountSettingEditPassord/>} 
                       {selectSetting === 2 && [...new Array(5).fill(0)].map(x => <NotificationType/>)}
                  </div>
              </div>
          </main>
    </div>
  )
}

export default AccountSetting