export const fetchAPI = async (url, options) => {
  try {
    const res = await fetch(url, { ...options })
    const jsonResponse = await res.json();
    if (!res.ok) {
      const err = new Error(jsonResponse.message);
      err.statusCode = res.status;
      err.message = jsonResponse.message;
      err.errors = jsonResponse.errors;
      throw err;
    }
    return jsonResponse;

  } catch (e) {
    if (!e.statusCode) {
      const message = "Unable to connect, Check you network and try again";
      const err = new Error(message);
      err.statusCode = 500;
      err.message = message;
      err.errors = { network: "Unable to connect, Check you network and try again" };
      throw err;
    }

    throw e;
  }
}