import React from 'react'
import {FaTiktok} from "react-icons/fa";
import {AiFillInstagram,AiFillLinkedin} from "react-icons/ai";
import {BsTwitter} from "react-icons/bs";
import {BiLogoFacebook} from "react-icons/bi";
import logo from "../../assets/images/logo.png";
import "./footer-homepage.css";

function FooterHomepage() {
  return (
    <footer className='homepage-footer'>
        <div className="homepage-footer-container">
            <div className="homepage-footer-logo">
                <img src={logo} alt="Newbanger logo" />
            </div>
            <ul className="homepage-explore-list">
                <h3>Explore</h3>
                <li>About Us</li>
                <li>How it works</li>
                <li>Blog</li>
            </ul>

            <ul className="homepage-explore-list">
                <h3>Support</h3>
                <li>Faq</li>
                <li>Contact Us</li>
            </ul>

            <ul className="homepage-explore-list">
                <h3>Legal</h3>
                <li>Privacy Policy</li>
                <li>Musician T&C</li>
                <li>Curators T&C</li>
            </ul>
        </div>
        <div className="homepage-footer-note">
            <p>Copyright 2023 Newbanger Digital Limited</p>
            <div className="homepage-social-media-icons">
                <span><FaTiktok/></span>
                <span><AiFillInstagram/></span>
                <span><BsTwitter/></span>
                <span><AiFillLinkedin/></span>
                <span><BiLogoFacebook/></span>
            </div>
        </div>
    </footer>
  )
}

export default FooterHomepage