import React from "react";

import "./added-platform-card.css"

import { AiOutlineStar } from "react-icons/ai"

export default function AddedPlatformCard({ platform, removePlatform }) {
  return (
    <div className="added-platform-card">
      <div className="container flex justify-between items-center">
        <div className="card-content-container">
          <div className="about-platform-container flex gap1">
            <div className="logo-container flex1">
              <img src={platform.logo} alt="platform-logo" className="platform-logo" />
            </div>

            <div className="text-details flex1">
              <h2 className="name">{platform.name}</h2>
              <p className="country">Price: {platform.price} Per Post</p>
              <p className="type">Platform Type: {platform.type}</p>
            </div>
          </div>
          <div className="ratings-container gap1 flex items-center">
            <div className="visitors flex1">Visitors: {platform.visitors}</div>

            <div className={`rating ${platform.rating} flex1`}>
              {Array.from({ length: 5 }, (_, index) => index).map((x,i) => 
              <AiOutlineStar style={{ fontSize: "20px", color: `${i < platform.rating ?'green':''}`}} />
            )}
            </div>
          </div>
        </div>

        <div className="remove-platform-btn-container" onClick={removePlatform}>
          <button className="remove-platform-btn flex1">Remove</button>
        </div>

      </div>
    </div>
  )
}