import React,{useEffect} from "react";
import "./default.css";
import Header from "../../../components/header/Header";
import BloggerNav from "../../../components/blogger-nav/BloggerNav";

import laptop from "../../../assets/images/laptop.png"
import VerifyNotificationCard from "../../../components/verify-notification-card/VerifyNotificationCard";
import { useContext } from "react";
import { AuthContext } from "../../../context/Context";
import { useNavigate } from "react-router-dom";

function BloggerWelcome() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();
  const [showSideNav, setShowSideNav] = React.useState(true)
  const toggleShowSideNav = () => setShowSideNav(a => !a);

  useEffect(() => {
    if (!auth.state?.loggedIn) {
      navigate(`/login?returnUrl=${encodeURIComponent('/blogger-welcome')}`)
      return
    }
    if(auth.state?.user?.user_type === 'curator') navigate('/curator-complete-signup')
    else if(auth.state?.user?.user_type === 'influencer') navigate('/influencer-complete-signup')
},[auth,navigate])

  return (
    <div className="blogger-welcome">
      <BloggerNav showSideNav={showSideNav} />
      <main className="blogger-welcome-main">
        <Header showSideNav={showSideNav} toggleShowSideNav={toggleShowSideNav} />

        <div className="body">
          <VerifyNotificationCard />
          <div className="welcome-card">
            <div className="laptop-container">
              <img src={laptop} alt="laptop" />
              <p className="greeting">Hello Current User</p>
              <p className="text">We understand here look  dry at the moment,
                we will get you contents to monetize soon</p>
            </div>

            <button className="add-payout-account-btn">Add Payout Account</button>
          </div>
        </div>
      </main>
    </div>
  )
}

export default BloggerWelcome;