
export const authReducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return { ...state, isLoading: false, success: false, error: null, user: null, loggedIn: false, message: ""  };
        case 'loading':
            return { ...state, isLoading: true, error: null, success: false };
        case 'error':
            return {...state, isLoading: false, error: action.payload, success: false}
        case 'register':
            return { ...state, message: action.payload, error: null, isLoading: false, success: true };
        case 'verify':
            return { ...state, message: action.payload, error: null, isLoading: false, success: true };
        case 'login':
            return { ...state, message: action.payload.message, user: action.payload, error: null, isLoading: false, success: true, loggedIn: true };
        case 'update-user':
            return {...state, user: action.payload, error: null, isLoading: false, success: true, loggedIn: true};
        case 'forget-password':
            return {...state, message: action.payload, error: null, isLoading: false, success: true};
        case 'reset-password':
            return { ...state, message: action.payload, error: null, isLoading: false, success: true };
        case 'blogger-complete-signup':
                return {...state, message: action.payload, error: null, isLoading: false, success: true};
        case 'logout':
            return {...state, message: action.payload, error: null, user: null, success: true, isLoading: false, loggedIn: false};
        default:
            return state;
    }
}