import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/website/login/Login';
import Home from './pages/website/homepage/Home';
import NotFound from './pages/not-found/NotFound';
import AccountSetup from './pages/website/account-setup/AccountSetup';
import MusicianSignup from './pages/website/sign-up/musician-signup/MusicianSignup';
import MusicianConfirmMail from './pages/website/sign-up/musician-confirm-mail/MusicianConfirmMail';
import CuratorSignup from './pages/website/sign-up/curator-signup/CuratorSignup';
import CuratorConfirmMail from './pages/website/sign-up/curator-confirm-mail/CuratorConfirmMail';
import BloggerCompleteSignup from './pages/website/sign-up/blogger-complete-signup/BloggerCompleteSignup';
import InfluencerCompleteSignup from './pages/website/sign-up/infuencer-complete-signup/InfluencerCompleteSignup';
import InfluencerWelcome from './pages/website/influencer-welcome/InfluencerWelcome';
import ForgotPassword from './pages/website/forgot-password/ForgotPassword';
import ResetPassword from './pages/website/reset-password/ResetPassword';
import AboutUs from './pages/website/about-us/AboutUs';
import BloggerWelcome from './pages/website/default/BloggerWelcome';
import MusicianDashboardOverview from './pages/website/musician-dashboard-overview/MusicianDashboardOverview';
import Campaigns from './pages/website/campaigns/Campaigns';
import StartCampaign from './pages/website/start-campaign/StartCampaign';
import CreateBlogCampaign from './pages/website/create-blog-campaign/CreateBlogCampaign';
import CuratorCompleteSignup from './pages/website/sign-up/curator-complete-signup/CuratorCompleteSignup';
import AccountSetting from './pages/website/account-setting/AccountSetting';
import BloggerDashboardContent from './pages/website/blogger-dashboard-content/BloggerDashboardContent';
import BloggerSupport from './pages/website/blogger-support/BloggerSupport';
import BloggerPayment from './pages/website/blogger-payment/BloggerPayment';
import CreateInfluencerCampaign from "./pages/website/create-influencer-campaign/CreateInfluencerCampaign";

import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from './context/Context';
import { getUser } from './apis/auth';
import Payment from './pages/website/payment/Payment';
import ViewCampaign from './pages/website/view-campaign/ViewCampaign';
import AddInfluencer from "./pages/website/add-influencer/AddInfluencer";

function App() {
  const { state, dispatch } = useContext(AuthContext);
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!state?.loggedIn && token) {
      (async () => {
        const data = await getUser(token);
        if (data?.errors) {
          localStorage.clear("token")
          dispatch({ type: "error", payload: data });
        }
        else dispatch({ type: "update-user", payload: data })
      }
      )()
    }
  }, [state, dispatch])
  return (
    <Routes>
      <Route path='*' element={<NotFound />} />
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/auth/reset-password/:token' element={<ResetPassword />} />
      <Route path='/account-setup' element={<AccountSetup />} />
      <Route path='/musician-signup' element={<MusicianSignup />} />
      <Route path='/curator-signup' element={<CuratorSignup />} />
      <Route path='/auth/email/verify/:id/:hash' element={<MusicianConfirmMail />} />
      <Route path='curator-confirm-mail' element={<CuratorConfirmMail />} />
      <Route path='/curator-complete-signup' element={<CuratorCompleteSignup />} />
      <Route path='/blogger-complete-signup' element={<BloggerCompleteSignup />} />
      <Route path='/influencer-complete-signup' element={<InfluencerCompleteSignup />} />
      <Route path='/influencer-welcome' element={<InfluencerWelcome />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path="/blogger-welcome" element={<BloggerWelcome />} />
      <Route path="/musician-dashboard-overview" element={<MusicianDashboardOverview />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/start-campaign" element={<StartCampaign />} />
      <Route path="/view-campaign/:campaignId" element={<ViewCampaign />} />
      <Route path="/create-blog-campaign" element={<CreateBlogCampaign />} />
      <Route path="/create-blog-campaign/:slug" element={<CreateBlogCampaign />} />
      <Route path="/payment/:slug" element={<Payment />} />
      <Route path="/account-setting" element={<AccountSetting />} />
      <Route path="/blogger-content" element={<BloggerDashboardContent />} />
      <Route path="/blogger-payment" element={<BloggerPayment />} />
      <Route path="/blogger-support" element={<BloggerSupport />} />
      <Route path="/create-influencer-campaign" element={<CreateInfluencerCampaign />} />
      <Route path="/add-influencers" element={<AddInfluencer />} />
    </Routes>

  );
}

export default App;
