import "./homepage-card.css"

function HomepageCard({ img, heading, text }) {
  return (
    <div className="homepage-card">
      <img src={img} alt="icon" />
      <h3>{heading}</h3>
      <p>{text}</p>
    </div>
  )
}

export default HomepageCard