import { BASE_URL } from "../utils/commons";

export const updateCuratorType = async (data) => {
    let token = localStorage.getItem("token");
    try {
        const res = await fetch(BASE_URL + "/user/user-type", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ user_type: data })
        })
        if (!res.ok) {
         return {errors: "Unable to update your user type. Please try again."}   
        }
        return await res.json();
    } catch (e) {
        return {message: "Unable to connect, Check you network and try again", errors: "Unable to connect, Check you network and try again"};
    }
}