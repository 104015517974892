import React from 'react'

function VerticalProgressBar({ pageNumber }) {
  const actions = ["Add Music Content","Choose Platforms","Finalize"]
  return (
    <div className="progress-container">
      <div className={`steps flex ${pageNumber === 1 ? "one" : pageNumber === 3 ? "two" : pageNumber === 4 ? "three" : ""}`}>
        {actions.map((act,i )=>
          <div className={`step step-${i===0?'one':i===1?'two':'three'} flex gap items-center`}>
            <div className="circle"></div>
            <div className="flex direct-column gap1">
              <span className="step-heading">{act}</span>
              <span className="step-number">Step { i+1}</span>
            </div>
          </div>
        )}
        <div className={`animating-progress-container ${pageNumber === 2 ? "two" : pageNumber === 3 ? "three" : ""}`}>
          <div className="animating-bar"></div>
        </div>
      </div>
    </div>
  )
}

export default VerticalProgressBar