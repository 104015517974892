import React from 'react'
import Selfie from "../../../assets/images/sidebar-img-light.png"
import "./influencer-welcome.css"
import FooterDashboard from '../../../components/footer-dashboard/FooterDashboard'
import NavbarDashboard from '../../../components/navbar-dashboard/NavbarDashboard'

function InfluencerWelcome() {
  return (
    <div className="bloger-welcome">
        <NavbarDashboard/>
        <div className="blogger-welcome-screen">
            <img src={Selfie} alt="a laptop" />
            <p>one More thing before we get started, kindly complete your profile  click the<br/> Button below</p>
            <button>Complete Your Profile</button>
        </div>
        <FooterDashboard />
    </div>
    
  )
}

export default InfluencerWelcome