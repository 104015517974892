import React, { useReducer } from 'react'
import { AuthContext } from '../../context/Context';
import { authReducer } from './authReducer';

function AuthProvider({children}) {
    const initialState = { isLoading: false, success: false, error: null, user: null, loggedIn: false, message: "" };
    const [state, dispatch] = useReducer(authReducer, initialState);
    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
      
  )
}

export default AuthProvider