import React, { useState } from 'react'
import WebsiteSidebar from '../../../components/website-sidebar/WebsiteSidebar';
import "./account-setup.css";
import user from "../../../assets/svg/user.svg"
import userActive from "../../../assets/svg/user-active.svg"
import bag from "../../../assets/svg/bag.svg"
import bagActive from "../../../assets/svg/bag-active.svg"
import { useNavigate } from 'react-router-dom';

function AccountSetup() {
    const[curatorActive, setCuratorActive] = useState(false);
    const [musicianActive, setMusicianActive] = useState(false);
    const navigate = useNavigate();

    const handleMusicianActive = () => {
        setCuratorActive(false);
        setMusicianActive(!musicianActive);
    }

    const handleCuratorActive = () => {
        setMusicianActive(false);
        setCuratorActive(!curatorActive);
    }
    const register = () => {
        if(curatorActive) navigate("/curator-signup")
        if(musicianActive) navigate("/musician-signup")
    }
  return (
    <div className='account-setup-page'>
        <WebsiteSidebar />
        <div className="account-setup-content">
            <div className="account-content-container">
                <h3>Get Started With An Account Type</h3>
                <div className="select-user-type">
                    <div className={musicianActive ? "select-musician musician-active": "select-musician"} onClick={handleMusicianActive}>
                        {musicianActive ? <img src={userActive} alt="" />:<img src={user} alt="" />}
                        <h4>As a Musician</h4>
                        <p>Start promoting your music</p>
                        <div className={musicianActive ? 'select select-active':'select'}></div>
                    </div>
                    <div className={curatorActive? "select-curator curator-active": "select-curator"} onClick={handleCuratorActive}>
                        {curatorActive?<img src={bagActive} alt="" />:<img src={bag} alt="" />}
                        
                        <h4>As a Curator</h4>
                        <p>Get paid promoting music</p>
                        <div className={curatorActive ? 'select select-active':'select'}></div>
                    </div>
                </div>
                <div className="continue-back-btns">
                    <div className="btn-container">
                        <button onClick={() => navigate(-1)}>Go Back</button>
                        <button onClick={register} className={curatorActive || musicianActive ? "btn-active" :""}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccountSetup