import React from "react";

import "./platform-card.css"

import { AiOutlineStar } from "react-icons/ai"
import { BiBookmark } from "react-icons/bi"
import { IoCheckmarkSharp } from "react-icons/io5";

export default function PlatformCard({ platform,updatePlatform }) {
  return (
    <div className="platform-card" onClick={updatePlatform}>
      <div className="card-content-container">
        <div className="about-platform-container flex gap1">
          <div className="logo-container flex1">
            <img src={platform?.logo} alt="platform-logo" className="platform-logo" />
          </div>
          <div className="text-details flex1">
            <h2 className="name">{platform?.name}</h2>
            <p className="country">{platform?.country}</p>
            <p className="type">Blog</p>
          </div>
        </div>
        <div className="ratings-container gap1 flex items-center">
          <div className="visitors flex1">Visitors: {platform?.estimated_monthly_views}</div>

          <div className={`rating ${platform?.rating} flex1`}>
            {Array.from({ length: 5 }, (_, index) => index).map((x,i) => 
              <AiOutlineStar style={{ fontSize: "20px", color: "green"}} />
            )}
          </div>
        </div>
      </div>

      <div className="platform-card-action-container flex">
        <button className="add-btn flex1">
          {platform?.charge_amount?.toLocaleString('en-US', {
            style: 'currency',
            currency: "NGN",
            minimumFractionDigits: 1,
        })|| "NGN 0.0"} Per Post
        </button>

        <button className="bookmark-btn">
          {!platform?.selected ?
            <BiBookmark style={{ fontSize: "20px" }} />:
            <IoCheckmarkSharp style={{ fontSize: "20px", color: "#FF614D" }}/>
          }
        </button>
      </div>
    </div>
  )
}