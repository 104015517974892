import React from 'react'
import "./curator-complete-signup.css"
import FooterDashboard from '../../../../components/footer-dashboard/FooterDashboard';
import NavbarDashboard from '../../../../components/navbar-dashboard/NavbarDashboard';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpinningCircles } from 'react-loading-icons'
import { updateCuratorType } from '../../../../apis/curator';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/Context';
import EmailVerfificationWarning from '../../../../components/email-verification-warning/EmailVerfificationWarning';
import { centerErrorAlert } from '../../../../utils/alert';
import { useEffect } from 'react';

function CuratorCompleteSignup() {
  const navigate = useNavigate();
  const [curator, setCurator] = useState('')
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  useEffect(() => {
    if (!state?.loggedIn) {
      navigate(`/login?returnUrl=${encodeURIComponent('/curator-complete-signup')}`)
      return
    }
    console.log("Curator STate", state?.user?.user_type)
    if (state?.user?.user_type.trim() === 'blogger') {
      navigate('/blogger-complete-signup')
    } 
    else if (state?.user?.user_type.trim() === 'influencer') {
      navigate('/influencer-complete-signup');
    } 
    else if (state?.user?.user_type.trim() === 'musician') {
      navigate('/')
    } 
  }, [state,navigate])
  const handleClick = async () => {
    if (curator === '') return;
    setLoading(true);
    let data = await updateCuratorType(curator);
    if (data?.errors) {
      setLoading(false);
      centerErrorAlert(data?.errors);
      return
    } 
    if (curator === 'blogger') navigate('/blogger-complete-signup');
    else navigate('/influencer-complete-signup')
  }
  const curatorSelection = (cur) => {
    if (!state?.user?.email_verified_at) {
      centerErrorAlert("You must verify your mail to continue.")
      return;
    } 
    setCurator(cur);
  }
  return (
    <div className='complete-signup-page'>
        <NavbarDashboard />
        {!state?.user?.email_verified_at && <EmailVerfificationWarning />}
        <section className="select-curator-type">
            <h4>I am a ...</h4>
            <div className="curator-options">
                  <span onClick={() => curatorSelection("blogger")} className={curator === 'blogger'?'selected-curator':''}>Blogger</span>
                <span onClick={() => curatorSelection("influencer")} className={curator === 'influencer'?'selected-curator':''}>Social Media Influencer</span>
                <span>Playlist Curator</span>
                <span>Show Promoter</span>
            </div>
            <div className="continue-previous-btns">
              {loading ?
                <button className='selected-option'><SpinningCircles strokeWidth={.1}/></button>:
                <button className={curator === '' ? '' : 'selected-option'} onClick={handleClick}>Select</button>
              }
            </div>
        </section>
        <FooterDashboard />
    </div>
  )
}

export default CuratorCompleteSignup