import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";

import logo from "../../assets/images/logo-l.png";
import logoIII from "../../assets/images/logo-III.png";

import "./navbar-homepage.css";
import { useContext } from "react";
import { AuthContext } from "../../context/Context";

function NavbarHomepage() {
  const { state } = useContext(AuthContext);
  const [menu, setMenu] = useState(true);
  const style = { color: "#FF614D" };
  return (
    <nav className="homenav">
      <div className="homenav-container">
        <div className="homenav-logo">
          <Link to="/">
            <picture>
              <source media="(min-width: 750px)" srcSet={logoIII} />
              <source media="(min-width: 750px)" srcSet={logo} />
              <img src={logo} alt="our logo" />
            </picture>
          </Link>
          <span
            className="toggle-burger"
            onClick={() => setMenu((menu) => !menu)}
          >
            {menu ? <GrFormClose /> : <GiHamburgerMenu style={style} />}
          </span>
        </div>

        {menu && (
          <>
            <ul className="homenav-links">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="#">Promote Your Music </Link>
              </li>
              <li>
                <Link to="#">How it works</Link>
              </li>
              <li>
                <Link to="#">Blog</Link>
              </li>
              <li>
                <Link to="#">Curator/pro</Link>
              </li>
            </ul>
            {!state.loggedIn ? (
              <div className="homenav-btn-links">
                <Link to="/login" className="homenav-btn homenav-login-btn">
                  Login
                </Link>
                <Link
                  to="/account-setup"
                  className="homenav-btn homenav-signup-btn"
                >
                  Sign Up
                </Link>
              </div>
            ) : (
              <div className="homenav-btn-links">
                <Link
                  to={
                    state.user.user_type === "musician"
                      ? "/musician-dashboard-overview"
                      : "/blogger-content"
                  }
                  className="homenav-btn homenav-login-btn"
                >
                  Dashboard
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </nav>
  );
}

export default NavbarHomepage;
