import Swal from "sweetalert2";

export const alertSuccessTopRight = (txt) => {
    Swal.fire({
        position: "top-end",
        icon: "success",
        title: txt,
        showConfirmButton: true,
        timer: 35000,
        cancelButtonColor: '#FF614D',
        confirmButtonColor: '#FF614D',
    });
}

export const centerErrorAlert = (txt) => {
    Swal.fire({
        title: "Oops...",
        text: txt,
        icon: "error",
        cancelButtonColor: "#ff614d",
        confirmButtonColor: '#FF614D',
      });
}