import React, { useState } from 'react';
import { BsChatSquareText, BsEnvelope } from "react-icons/bs";
import { PiWhatsappLogo } from "react-icons/pi";

import "./blogger-support.css";
import BloggerNav from '../../../components/blogger-nav/BloggerNav';
import Header from '../../../components/header/Header';

import customerCareIcon from "../../../assets/images/customer-support.png"
import avatar from "../../../assets/images/walee.png"

import Faquestion from "../../../components/faq/faq";

export default function BloggerSupport() {
  const [showSideNav, setShowSideNav] = useState(true)
  const toggleShowSideNav = () => setShowSideNav(a => !a);

  return (
    <div className="blogger-support">
      <BloggerNav showSideNav={showSideNav} />

      <main className="blogger-support-main">
        <Header showSideNav={showSideNav} toggleShowSideNav={toggleShowSideNav} avatar={avatar} />

        <div className="body">
          <h2 className="heading">Support</h2>

          <div className="support-container flex direct-column items-center">
            <div className="customer-support-image"><img src={customerCareIcon} alt="customer care" /></div>
            <p className="text">We are here to provide all the support that you need, you can create a ticket below or reach out</p>
            <button className="create-ticket-btn">Create Ticket</button>
          </div>

          <div className="support-action-btn-container">
            <button className="support-action-btn flex direct-column gap1 items-center">
              <div className="icon-container"><BsChatSquareText style={{ fontSize: "40px", lineHeight: "0px", fontWeight: "500" }} /></div>
              <span className="action-btn-desc">Live Chat</span>
            </button>
            <button className="support-action-btn flex direct-column gap1 items-center">
              <div className="icon-container"><PiWhatsappLogo style={{ fontSize: "40px", lineHeight: "0px", fontWeight: "500" }} /></div>
              <span className="action-btn-desc">Whatsapp</span>
            </button>
            <button className="support-action-btn flex direct-column gap1 items-center">
              <div className="icon-container"><BsEnvelope style={{ fontSize: "40px", lineHeight: "0px", fontWeight: "500" }} /></div>
              <span className="action-btn-desc">Send Email</span>
            </button>
          </div>

          <div className="faquestion">
            <Faquestion />
          </div>
        </div>
      </main>
    </div>
  )
}