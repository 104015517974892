import React, { useState } from 'react';
import "./curator-confirm-mail.css";
import {BsCheckLg} from 'react-icons/bs';
import WebsiteSidebarL from '../../../../components/website-siderbar-lg/WebsiteSidebarL';

function CuratorConfirmMail() {
  const [showNotification, setShowNotification] = useState(true);
  return (
    <div className='curator-confirmation-page'>
        <WebsiteSidebarL />
        <section className='login-section'>
            <div className="signup-success-container">
                <div className="signup-success-content">
                    <h3>Confirm your email</h3>
                    <p>Hurray! You have successfully confirmed your mail. You can now login to start uploading your songs.</p>
                </div>
            </div>
        </section>
        <div className={showNotification? "notification-top show-notification":"notification-top"}>    
            <div className="confirmation-icon-text">
                <div className="success-icon">
                    <div className='success-inner-circle'><BsCheckLg /></div>
                </div>
                <div className="success-text">
                    <h4>Email Confirmed</h4>
                    <p>Your Email verification was successful</p>
                </div>
            </div>
            <span className='close-btn' onClick={() => setShowNotification(false)}>X</span>
        </div>
    </div>
  )
}

export default CuratorConfirmMail
