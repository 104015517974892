import React from 'react'

import ContentRequestCard from '../../../../components/content-request-card/ContentRequestCard'

import "./content-request.css"
import image from "../../../../assets/images/content-request.png"

export default function ContentRequest() {
    return (
        <div className="content-request">
            <div className="head">Content Request</div>

            <div className="content-request-main-body">
                <ContentRequestCard image={image}/>
                <ContentRequestCard image={image}/>
            </div>
        </div>
    )
}