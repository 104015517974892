import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";

import "./start-campaign.css";
import Header from "../../../components/header/Header";
import Sidenav from "../../../components/sidenav/Sidenav";

import icon from "../../../assets/images/blog-campa.png";
import icon2 from "../../../assets/images/influencer-campa.png";
import logo from "../../../assets/images/logo-l.png";
import { CreateCampaignContext } from "../../../context/Context";

function StartCampaign() {
  const [showSideNav, setShowSideNav] = useState(true)
  const { state, dispatch } = useContext(CreateCampaignContext);
  const { campaignDetails } = state;
  const toggleShowSideNav = () => setShowSideNav(a => !a);

  const onInputChange = e => {
    dispatch({type:"campaignType", payload: e.target.value});
  }
  console.log(campaignDetails)
  return (
    <div className="start-campaign">
      <Sidenav showSideNav={showSideNav} />
      <main className="start-campaign-main">
        <Header showSideNav={showSideNav} toggleShowSideNav={toggleShowSideNav} pageHeading={<img src={logo} alt="logo" />} />

        <div className="body">
          <h2 className="body-heading">Select Campaign Type</h2>
          <form>
            <div className="form-main-container">
              <div className="label-container">
                <input type="radio" name="campaign-type" id="blog-campaign" value="blog" onClick={onInputChange} checked={campaignDetails.campaign_type==="blog" } />
                <label className="nocampaign-card" htmlFor="blog-campaign">
                  <div className="container">
                    <img src={icon} alt="icon" />
                    <h3 className="desc-heading">Blog Campaign </h3>
                    <p className="suggestion-text">boost your online presence, Get your song and other contents published on influential music blogs</p>
                    <div className="checkmark-icon-container">
                      <IoIosCheckmarkCircle className="checkmark" />
                    </div>
                  </div>
                </label>
              </div>
              <div className="label-container">
                <input type="radio" name="campaign-type" id="influencer-campaign" value="social_media" onClick={onInputChange} checked={campaignDetails.campaign_type==="social_media" } />
                <label className="nocampaign-card" htmlFor="influencer-campaign">
                  <div className="container">
                    <img src={icon2} alt="icon" />
                    <h3 className="desc-heading">Influencer Campaign</h3>
                    <p className="suggestion-text">Make Your music go viral by working with influencers on Facebook,Tiktok,twitter and Instagram</p>
                    <div className="checkmark-icon-container">
                      <IoIosCheckmarkCircle className="checkmark" />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            
            <Link to={`${campaignDetails.campaign_type === "" ? "" : campaignDetails.campaign_type === "blog"? "/create-blog-campaign":"create-influencer-campaign"}`} className="next-btn">Next</Link>
          </form>
        </div>
      </main>
    </div>
  )
}

export default StartCampaign;