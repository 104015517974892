import React, { useState } from 'react';
import "./musician-confirm-mail.css";
import {BsCheckLg} from 'react-icons/bs';
import WebsiteSidebar from '../../../../components/website-sidebar/WebsiteSidebar';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/Context';
import { resendVerificationMail, verifyMail } from '../../../../apis/auth';

function MusicianConfirmMail() {
    const [showNotification, setShowNotification] = useState(false);
    const { id, hash } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const expires = searchParams.get('expires');
    const signature = searchParams.get('signature');
    const { state, dispatch } = useContext(AuthContext);

    useEffect(() => {
        dispatch({ type: 'reset' })
    }, [dispatch])
    useEffect(() => {
        const redirect = () => {
            if (expires === null || signature === null) navigate("/")
        }
        const handleEmailVerification = async () => {
            dispatch({ type: "loading" });
            const res = await verifyMail({ id, hash, expires, signature });
            if(res?.errors) dispatch({ type: "error", payload: res.errors })
            else dispatch({ type: "verify", payload: res.message })
        }
        redirect();
        handleEmailVerification();
    }, [navigate, expires, signature,dispatch,id,hash])
    
    useEffect(() => {
        setShowNotification(state.success);
    },[state])
    
    const handleResend = async () => {
        dispatch({ type: "loading" });
        const res = await resendVerificationMail({ id, hash, expires, signature });
        if(res?.errors) dispatch({ type: "error", payload: res.errors })
        else dispatch({ type: "verify", payload: res.message })
    }
  return (
    <div className='musician-confirmation-page'>
          <WebsiteSidebar />

              <section className='login-section'>
                <div className="signup-success-container">
                  <div className="signup-success-content">
                        <h3>{state?.isLoading ? "Loading" : (state?.success ? "Email Verification" : "Unable to Verify Email")}</h3>
                        {state?.isLoading ? <p>Please wait...</p> : 
                            <p>{state.message || state.error} {console.log("state",state)}. Click here to { state.success ?<Link className='link-resend' to={'/login'}>Login</Link>:<small className='link-resend' onClick={handleResend}>Resend Link</small> }</p>
                        }
                    </div>  
                </div>
              </section>
        <div className={showNotification? "notification-top show-notification":"notification-top"}>    
            <div className="confirmation-icon-text">
                <div className="success-icon">
                    <div className='success-inner-circle'><BsCheckLg /></div>
                </div>
                <div className="success-text">
                    <h4>Email Confirmed</h4>
                      <p>{ state.message}</p>
                </div>
            </div>
            <span className='close-btn' onClick={() => setShowNotification(false)}>X</span>
        </div>
    </div>
  )
}

export default MusicianConfirmMail
