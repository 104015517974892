
export const bloggerReducer = (state, action) => {
    switch (action.type) {
        case 'error':
            return {...state, loading: false, success: false, error: action.payload};
        case 'loading':
            return { ...state, loading: true, success: false, error: null};
        case 'blogger-complete-signup':
            return {...state, loading: false, error: null, success: true, blogger: action.data, message: action.payload.message};
        default:
            return state;
    }
}