import React, { useContext, useState } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { campaignConfirmPayment, getCampaign } from "../../../apis/musician";
import { alertSuccessTopRight, centerErrorAlert } from "../../../utils/alert";
import {PaystackButton} from "react-paystack"


import Header from '../../../components/header/Header';

import logo from "../../../assets/images/logo-l.png";

import "./payment.css"
import { AuthContext, CreateCampaignContext } from "../../../context/Context";

export default function Payment() {
  const publicKey = "pk_test_6426d10b7366a4a986249d29d2ed29e1ef8d49fc"
  const navigate = useNavigate();
  const { slug } = useParams();
  const { state } = useContext(AuthContext);
  const { user } = state
  const [total, setTotal] = useState(0);
  
  console.log("User details",state)
  const componentProps = {
    email: user.email,
    amount: total*100,
    publicKey,
    text: "Pay Now",
    onSuccess: (val) =>
      confirmPayment({
          "payment_gateway": "paystack",
          "transaction_id": val.reference
      }),
    onClose: (val) => console.log(val),
  }
  const confirmPayment = async (data) => {
    try {
      const res = await campaignConfirmPayment(slug, data)
      console.log(res)
      alertSuccessTopRight(res.message)
    } catch (error) {
      centerErrorAlert("Unable to make payment")
    }
  }

  const fetchCampaign = async () => {
    try {
      const res = await getCampaign(slug);
      setTotal(+res.data.amount_to_pay);
    } catch (error) {
      centerErrorAlert("Cannot fetch this campign. Please try again or create new campaign")
      navigate(`/create-blog-campaign/${slug}`)
    } 
  }
  fetchCampaign()
  return (
    <div className="payment">
      <Header pageHeading={<img src={logo} alt="logo" />} />

      <main className="payment-main">
        <button className="prev-step" onClick={() => navigate(-1)}>Previous step </button>

        <div className="container">
          <h1 className="heading">ADD PAYMENT</h1>

          <div className="form-container">
            <h2 className="form-head">Select your payment Method</h2>

            <form className="payment-method-form flex direct-column gap">
              <h3 className="head-desc">Payment Method</h3>

              <select name="payment-option" id="payment-option">
                <option value="paystack">Paystack</option>
                <option value="remita">Remita</option>
              </select>
              <input type="text" name="amount" id="amount" value={total} readOnly />
            </form>
            <PaystackButton className="pay-btn" {...componentProps} />
          </div>
        </div>
      </main>
    </div>
  )
}