import React from 'react'
import { Link } from 'react-router-dom';
import { BsFillCloudUploadFill } from "react-icons/bs"
import { HiCursorClick } from "react-icons/hi"
import { HiLightningBolt } from "react-icons/hi"
import { CgArrowLongDown } from "react-icons/cg"
import FooterHomepage from '../../../components/footer-homepage/FooterHomepage';
import NavbarHomepage from '../../../components/navbar-homepage/NavbarHomepage';
import BackgroundImageGrid from '../../../components/background-image-grid/BackgroundImageGrid';
import bgImage1 from '../../../assets/images/hg-1.png'
import bgImage2 from '../../../assets/images/hg-2.png'
import bgImage3 from '../../../assets/images/hg-6.png'
import bgImage4 from '../../../assets/images/hg-4.png'
import bgImage5 from '../../../assets/images/hg-5.png'
import bgImage6 from '../../../assets/images/hg-3.png'
import mobileHeaderImg from '../../../assets/images/mm.png'
import costIcon from '../../../assets/images/cost.png'
import networkIcon from '../../../assets/images/network.png'
import resultIcon from '../../../assets/images/result.png'
import influencer from '../../../assets/images/influencer.png'
import blogger from '../../../assets/images/blogger.png'
import dj from '../../../assets/images/dj.png'
import promoter from '../../../assets/images/promoter.png'
import mobileHow from '../../../assets/images/how-mobile.png'
import fig1 from '../../../assets/images/fig-1.png'
import fig2 from '../../../assets/images/fig-2.png'
import fig3 from '../../../assets/images/fig-3.png'
import fig4 from '../../../assets/images/fig-4.png'
import fig5 from '../../../assets/images/fig-5.png'
import fig6 from '../../../assets/images/fig-6.png'


import './home.css'
import HomepageCard from "../../../components/homepage-card/HomepageCard";
import ImageCaption from "../../../components/image-caption/ImageCaption";
import Faquestion from "../../../components/faq/faq";

function Home() {
  // const style = { position: "relative", height: "829px", overflow: "hidden" }

  return (
    // <div>Home</div>
    <>
      <header className='homepage-header'>
        <BackgroundImageGrid
          image1={bgImage1}
          image2={bgImage2}
          image3={bgImage3}
          image4={bgImage4}
          image5={bgImage5}
          image6={bgImage6}
        />
        <NavbarHomepage />
        <div className='home-header-container'>
          <div>

            <h2>Music Promotion Just Got <span className='home-easier-span'>Easier</span></h2>
            <p>Newbanger is helping to reduce the costs associated with music promotion and enable a more direct and personalized approach to reaching new fans.</p>
            <Link to="#">Promote Your Music  </Link>
            <div className='mobile-header-img-div'>
              <img src={mobileHeaderImg} className='mobile-header-img' alt='mobile header'/>
            </div>
          </div>
        </div>
      </header>

      <section>
        <div className='homepage-section-container'>
          <h2>What makes Newbanger different?</h2>
          <div className="homepage-card-container">
            <HomepageCard
              img={costIcon}
              heading="Cost-Effective Promotion"
              text="Newbanger provides affordable promotional packages that fit the budgets of up-and-coming musicians."
            />
            <HomepageCard
              img={networkIcon}
              heading="Extensive Network"
              text="Newbanger offers a vast network of music professionals to help promote your music and expand your reach to new audiences."
            />
            <HomepageCard
              img={resultIcon}
              heading="Transparent Results"
              text="Track the effectiveness of your promotional campaigns in real-time with detailed analytics and metrics."
            />
          </div>
        </div>
      </section>

      <section>
        <div className="homepage-section-container">
          <h2>Connect With  Music professionals</h2>
          <p className="homepage-heading-paragraph">Bloggers, Social Media Influencers, Oaps, DjS, Playlist curators and more</p>

          <div className="homepage-img-caption-card-container">
            <ImageCaption
              image={influencer}
              caption="Influencers"
            />
            <ImageCaption
              image={blogger}
              caption="Bloggers"
            />
            <ImageCaption
              image={dj}
              caption="Djs"
            />
            <ImageCaption
              image={promoter}
              caption="Show Promoters"
            />
          </div>

          <Link to="#" className="homepage-campaign-btn">Create a Campagin</Link>
        </div>
      </section>

      <section className="homepage-how-section">
        <div className="homepage-mobile-how">
          <h2>How It works</h2>
          <img src={mobileHow} alt="mobile view" />
        </div>

        <div className="homepage-how-desktop-aside">
          <div className="homepage-how-container">
            <h2>How It works</h2>
            <p>Promote your music in few easy steps</p>

            <div className="how-content">
              <span className="how-it-works-row">
                <BsFillCloudUploadFill className="homepage-how-icon" />
                <span className="content-text">Add Your Content in few steps </span>
              </span>
              <span><CgArrowLongDown className="homepage-how-icon" /></span>

              <span className="how-it-works-row">
                <HiCursorClick className="homepage-how-icon" />
                <span className="content-text">Choose Platforms or Curators </span>
              </span>
              <span><CgArrowLongDown className="homepage-how-icon" /></span>

              <span className="how-it-works-row">
                <HiLightningBolt className="homepage-how-icon" />
                <span className="content-text">Finalize and Launch Campaign </span>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="homepage-section-container">
          <h2>Frequently asked questions</h2>

          <div className="homepage-faq">
            <Faquestion />
          </div>
        </div>
      </section>

      <section className="homepage-sub-footer-section">
        <BackgroundImageGrid
          image1={fig1}
          image2={fig2}
          image3={fig6}
          image4={fig4}
          image5={fig5}
          image6={fig3}
        />

        <div className="homepage-sub-footer">
          <h2>Are you a music curator or professional</h2>
          <p>Connect, Collaborate, and Amplify: Join NewBanger's vibrant community of music curators and professionals to unlock endless possibilities for promoting exceptional music.</p>
          <Link to="#">Apply as a curator</Link>
        </div>
      </section>

      <FooterHomepage />
    </>
  )
}

export default Home