import React, { useRef, useState } from 'react'
import { SpinningCircles } from 'react-loading-icons';
import { AiOutlineArrowLeft} from "react-icons/ai";
import {BsFillCheckCircleFill, BsUpload} from "react-icons/bs";
import "./complete-signup.css"
import { countryList } from '../../../../assets/utils/countries';
import FooterDashboard from '../../../../components/footer-dashboard/FooterDashboard';
import NavbarDashboard from '../../../../components/navbar-dashboard/NavbarDashboard';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext, BloggerContext } from '../../../../context/Context';
import { bloggerCompleteSignup } from '../../../../apis/blogger';
import { useEffect } from 'react';
import { centerErrorAlert } from '../../../../utils/alert';

function BloggerCompleteSignup() {
  const auth = useContext(AuthContext);
  const {state, dispatch} = useContext(BloggerContext)
  const fileInput = useRef(null);
  const navigate = useNavigate();
  let [curatorStep, setCuratorStep] = useState(1);
  const [blog_name, set_blog_name] = useState(""); 
  const [blog_website, set_blog_website] = useState(""); 
  const [blog_niche, set_blog_niche] = useState(""); 
  const [blog_country, set_blog_country] = useState(""); 
  const [blog_logo, set_blog_logo] = useState(null); 
  const [blog_age_range, set_blog_age_range] = useState(""); 
  const [blog_estimated_monthly_views, set_blog_estimated_monthly_views] = useState(""); 
  const [blog_instagram, set_blog_instagram] = useState(""); 
  const [blog_facebook, set_blog_facebook] = useState(""); 
  const [blog_twitter, set_blog_twitter] = useState(""); 
  const [blog_linkedin, set_blog_linkedin] = useState("");
  const [fileName, setFileName] = useState("");

    useEffect(() => {
        if (!auth.state?.loggedIn) {
          navigate(`/login?returnUrl=${encodeURIComponent('/blogger-complete-signup')}`)
          return
        }
        if(auth.state?.user?.user_type === 'curator') navigate('/curator-complete-signup')
        else if(auth.state?.user?.user_type === 'influencer') navigate('/influencer-complete-signup')
  },[auth,navigate])
  const handleSubmit = async (e) => {
      e.preventDefault();
      if (!(blog_facebook || blog_instagram || blog_linkedin || blog_twitter)) {
          centerErrorAlert("Please provide at least one of these social handles")
          return;
      }
      dispatch({ type: 'loading' });
      let formData = new FormData();
      formData.append('blog_name',blog_name)
      formData.append('blog_website',blog_website)
      formData.append('blog_niche',blog_niche)
      formData.append('blog_country',blog_country)
      formData.append('blog_logo',blog_logo)
      formData.append('blog_age_range',blog_age_range)
      formData.append('blog_estimated_monthly_views',blog_estimated_monthly_views)
      formData.append('blog_instagram',blog_instagram)
      formData.append('blog_instagram',blog_instagram)
      formData.append('blog_facebook',blog_facebook)
      formData.append('blog_twitter',blog_twitter)
      formData.append('blog_linkedin', blog_linkedin)
      const data = await bloggerCompleteSignup(formData);
      console.log(data);
      if (data?.errors) {
          dispatch({ type: 'error', payload: data?.errors })
          setCuratorStep(1)
          console.log("Satet", state)
          return
      }
      dispatch({ type: 'blogger-complete-signup', payload: data.data })
      navigate("/blogger-welcome");
  }
  
  const handleFile = (e) => {
    const file = e.target.files[0];
    if (!file?.type.startsWith("image/")) {
        centerErrorAlert("This is not an image");
        return;
    }
    setFileName(file?.name);  
    set_blog_logo(file);
  }
    
  
  return (
    <div className='complete-signup-page'>
        <NavbarDashboard />
        <section className="blogger-onboard-details">
            <div className="progress-bar">
                <div className='progess-point-icon'><BsFillCheckCircleFill /></div>
                {curatorStep >= 2 ?
                    <>
                        <div className='progress-line'></div>
                        <div className='progess-point-icon'><BsFillCheckCircleFill /></div>
                    </>
                    :
                    <>
                        <div className='change-bg'></div>
                        <div className='unchecked-progress-point'></div>
                    </>
                }
                {curatorStep === 3 ?
                    <>
                        <div className='progress-line'></div>
                        <div className='progess-point-icon'><BsFillCheckCircleFill /></div>
                    </>
                    :
                    <>
                        <div className='change-bg'></div>
                        <div className='unchecked-progress-point'></div>
                    </>
                }
                
            </div>
            {
                curatorStep > 1?
                <span className='back-icon' onClick={() => setCuratorStep(--curatorStep)}><AiOutlineArrowLeft/></span>:""
            }
            {curatorStep === 1?
                <div className="blogger-input-form-section-1">
                    <h4>Let's know more about you and your platform</h4>
                    <form action="">
                        <div className="form-field">
                            <label htmlFor="firstName">What is your blog/Website Name </label><br/>
                              <input type="text" value={blog_name} onChange={(e) => set_blog_name(e.target.value)} placeholder='https://blog.newbanger.com' name='firstName' />
                              {state?.error?.blog_name ? <small>{ state.error?.blog_name.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="lastName">What is your website url?</label><br/>
                              <input type="text" value={blog_website} onChange={(e) => set_blog_website(e.target.value)} placeholder='Enter Your Blog/website url' name='lastName' />
                              {state?.error?.blog_website ? <small>{ state.error?.blog_website.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">What best describe your  blog/website niche</label><br/>
                            <select name="" id="" value={blog_niche} onChange={(e) => set_blog_niche(e.target.value)}>
                                <option value="">--Select from dropdown</option>
                                <option value="Entertainment">Entertainment</option>
                                <option value="Gospel">Gospel</option>
                                <option value="Sport">Sport</option>
                                <option value="Politics">Politics</option>
                                <option value="Travel">Travel</option>
                              </select>
                              {state?.error?.blog_niche ? <small>{ state.error?.blog_niche.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">Country</label><br/>
                            <select value={blog_country} onChange={(e) => set_blog_country(e.target.value)}>
                                <option value="">--Select country from dropdown</option>
                                {countryList.map(country => <option key={country.country_code} value={country.country_code}>{country.name}</option>)}
                              </select>
                              {state?.error?.blog_country ? <small>{ state.error?.blog_country.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">Upload your blog/website logo</label><br/>
                            <input type="file" ref={fileInput} onChange={handleFile}/>
                            <div className='upload' onClick={() => fileInput.current.click()}><BsUpload/>{fileName||"Upload"}</div>
                        </div>
                        <div className="form-field">
                            <input type="button" value="Next" onClick={() => setCuratorStep(++curatorStep)}/>
                        </div>
                    </form>
                </div>:""
            }
            {curatorStep === 2 ?
                <div className="blogger-input-form-section-1">
                    <h4>We are interested in your platform</h4>
                    <form action="">
                        <div className="form-field">
                              <label htmlFor="email">How old is your blog/website?</label><br />
                              <input type="number" value={blog_age_range} onChange={(e) => set_blog_age_range(e.target.value)} />
                            {/* <select value={blog_age_range} onChange={(e) => set_blog_age_range(e.target.value)}>
                                <option value="">Select range</option>
                                <option value="0-2">0-2</option>
                                <option value="2-5">2-5</option>
                                <option value="5-10">5-10</option>
                                <option value="Above 10">&rsaquo; 10</option>
                              </select> */}
                              {state?.error?.blog_age_range ? <small>{ state.error?.blog_age_range.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">What is your estimated  monthly view</label><br />
                            <input type="number" value={blog_estimated_monthly_views} onChange={(e) => set_blog_estimated_monthly_views(e.target.value)} />
                            {/* <select value={blog_estimated_monthly_views} onChange={(e) => set_blog_estimated_monthly_views(e.target.value)}>
                                <option value="">Select your estimated monthly view</option>
                                <option value="less than 10k">&lsaquo; 10k</option>
                                <option value="10k - 50k">10k - 50k</option>
                                <option value="50k - 100k">50k - 100k</option>
                                <option value="Above 100k">&rsaquo; 100k</option>
                              </select> */}
                              {state?.error?.blog_estimated_monthly_views ? <small>{ state.error?.blog_estimated_monthly_views.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <input type="button" value="Next" onClick={() => setCuratorStep(++curatorStep)}/>
                        </div>
                    </form>
                </div>:""
            }
            {curatorStep === 3 ?
                <div className="blogger-input-form-section-1 reduce-space">
                    <h4>We are interested in connecting with you on social media </h4>
                    <p>you are required link atleast one social media platform </p>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-field">
                            <label htmlFor="firstName">Instagram </label><br/>
                              <input type="text" value={blog_instagram} onChange={(e) => set_blog_instagram(e.target.value)} />
                              {state?.error?.blog_instagram ? <small>{ state.error?.blog_instagram.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="lastName">Twitter</label><br/>
                              <input type="text" value={blog_twitter} onChange={(e) => set_blog_twitter(e.target.value)} />
                              {state?.error?.blog_twitter ? <small>{ state.error?.blog_twitter.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="lastName">Facebook</label><br/>
                              <input type="text" value={blog_facebook} onChange={(e) => set_blog_facebook(e.target.value)} />
                              {state?.error?.blog_facebook ? <small>{ state.error?.blog_facebook.map(str => str)}</small> : ""}
                        </div>
                        <div className="form-field">
                            <label htmlFor="email">LinkedIn</label><br/>
                              <input type="text" value={blog_linkedin} onChange={(e) => set_blog_linkedin(e.target.value)} />
                              {state?.error?.blog_linkedin ? <small>{ state.error?.blog_linkedin.map(str => str)}</small> : ""}
                        </div>
                          <div className="form-field">
                            {state?.loading ?
                                <button className='selected-option'><SpinningCircles strokeWidth={.1} /></button> :
                                <input type="submit" value="Done" />
                            }
                        </div>
                    </form>
                </div>
                : ""
            }
        </section> 
        <FooterDashboard />
    </div>
  )
}

export default BloggerCompleteSignup