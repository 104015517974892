import React from 'react';

import "./withdraw-fund.css";

export default function WithdrawFund() {
  return (
    <div className="withdraw-fund">
      <h2 className="heading">Payment Info</h2>

      <div className="withdraw-fund-body">
        <h4 className="text">You can withdraw below, select your</h4>

        <div className="select-amount-container flex direct-column gap1">
          <p className="text">input the amount you want to withdraw</p>

          <select name="select-amount" id="select-amount" className="select-amount">
            <option value="50000">N 50,000</option>
            <option value="40000">N 40,000</option>
            <option value="30000">N 30,000</option>
            <option value="20000">N 20,000</option>
            <option value="10000">N 10,000</option>
          </select>
        </div>

        <div className="amount-btn-container">
          <p className="text">Amount to add</p>
          <div className="button-container">
            <button className="amount-btn">N10,000</button>
            <button className="amount-btn">N20,000</button>
            <button className="amount-btn">N30,000</button>
            <button className="amount-btn">N40,000</button>
            <button className="amount-btn">N50,000</button>
          </div>
        </div>

        <input type="text" name="withdrawal-amount" id="withdrawal-amount" className="input-amount" placeholder="N10,000" />
        <button className="add-fund-btn">Add Funds</button>
      </div>
    </div>
  )
}