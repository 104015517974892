import React from 'react';
import { FaCaretDown } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";

import "./influencer-campaign-setup.css";

import InfluencerCampaignHeader from "../influencer-campaign-header/InfluencerCampaignHeader";
import ButtonFooter from "../button-footer/ButtonFooter";
import ProgressBar from "../progress-bar/ProgressBar";
import { RxCaretDown } from "react-icons/rx";

export default function InfluencerCampaignSetup() {

  return (
    <div className="campaign-setup">
      <InfluencerCampaignHeader />
      <div className="campaign-setup-body">
        <div className="progress-column">
          <ProgressBar />
        </div>
        {/* --------------------------------------------- */}
        <div className="main-column">
          <h2 className="heading">Campaign setup</h2>
          <div className="main-form-content">
            <div className="form-row flex direct-column gap1">
              <label htmlFor="campaign-name" className="label">Campaign name</label>
              <input type="text" name="campaign-name" id="campaign-name" className="text-input flex1" />
            </div>

            <div className="influencer-level">
              <h2 className="head-text">Influencer Level*</h2>
              <div className="options-row flex gap1">
                <input type="checkbox" name="micro-influencer" id="micro-influencer" className="checkbox" />
                <label htmlFor="micro-influencer" className="flex direct-column">
                  <span className="label-head">Micro-Influencers 5,000 - 20,000 followers)</span>
                  <span className="label-text">Price Range: ₦5,000 - ₦30,000 per promotion.</span>
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="mid-tier-influencer" id="mid-tier-influencer" className="checkbox" />
                <label htmlFor="mid-tier-influencer" className="flex direct-column">
                  <span className="label-head">Mid-Tier Influencers (20,000 - 100,000 followers)</span>
                  <span className="label-text">Price Range: ₦30,000 - ₦100,000 per promotion.</span>
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="macro-influencer" id="macro-influencer" className="checkbox" />
                <label htmlFor="macro-influencer" className="flex direct-column">
                  <span className="label-head">Macro- Influencers (100,000 -500,000 followers)</span>
                  <span className="label-text">Price Range: ₦100,000 - ₦500,000 per promotion.</span>
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="mega-influencer" id="mega-influencer" className="checkbox" />
                <label htmlFor="mega-influencer" className="flex direct-column">
                  <span className="label-head">Mega - Influencers (500,000 + followers)</span>
                  <span className="label-text">Price Range:  ₦500,000 and above per promotion.</span>
                </label>
              </div>
            </div>

            <div className="budget">
              <h2 className="head-text">What is your Budget?</h2>

              <div className="amount-input-container flex items-center gap16">
                <button className="currency-btn">NGN &nbsp;<RxCaretDown /></button>
                <input type="text" name="budget-mount" id="budget-amount" placeholder="Amount" className="budget-amount-input flex1" />
              </div>

              <div className="budget-button-container flex gap">
                <button className="budget-btn flex items-center gap1">
                  <div>
                    <p className="budget-btn-text">Currency</p>
                    <p className="budget-btn-head">NGN</p>
                  </div>
                  <IoCloseSharp />
                  <FaCaretDown />
                </button>
                <button className="budget-btn">
                  <p className="budget-btn-head">NGN</p>
                  <p className="budget-btn-text">50,000</p>
                </button>
              </div>
            </div>

            <div className="platform-preference">
              <h3 className="head-text">Platform preference</h3>

              <div className="platform-preference-row flex">
                <label htmlFor="twitter" className="platform-preference-capsule">
                  <div className="container flex gap items-center">
                    <input type="checkbox" name="platform" id="twitter" className="platform-preference-checkbox" />
                    <span className="platform-desc">Twitter</span>
                  </div>
                </label>
                <label htmlFor="facebook" className="platform-preference-capsule">
                  <div className="container flex gap items-center">
                    <input type="checkbox" name="platform" id="facebook" className="platform-preference-checkbox" />
                    <span className="platform-desc">Facebook</span>
                  </div>
                </label>
              </div>
              <div className="platform-preference-row flex">
                <label htmlFor="instagram" className="platform-preference-capsule">
                  <div className="container flex gap items-center">
                    <input type="checkbox" name="platform" id="instagram" className="platform-preference-checkbox" />
                    <span className="platform-desc">Instagram</span>
                  </div>
                </label>
                <label htmlFor="tiktok" className="platform-preference-capsule">
                  <div className="container flex gap items-center">
                    <input type="checkbox" name="platform" id="tiktok" className="platform-preference-checkbox" />
                    <span className="platform-desc">Tiktok</span>
                  </div>
                </label>
              </div>
            </div>

            <div className="select-container">
              <div className="select-row">
                <label htmlFor="gender" className="select-label">Gender</label>
                <select name="gender" id="gender" className="select">
                  <option value=""></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="select-row">
                <label htmlFor="location" className="select-label">Location</label>
                <select name="location" id="location" className="select">
                  <option value=""></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="select-row">
                <label htmlFor="country" className="select-label">Country</label>
                <select name="country" id="country" className="select">
                  <option value=""></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            <div className="age-group">
              <h2 className="head-text">Age Group</h2>
              <div className="options-row flex gap1">
                <input type="checkbox" name="eighteen-to-twentyfour" id="eighteen-to-twentyfour" className="checkbox" />
                <label htmlFor="eighteen-to-twentyfour" className="flex direct-column">
                  18 - 24
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="twentyfive-to-twentynine" id="twentyfive-to-twentynine" className="checkbox" />
                <label htmlFor="twentyfive-to-twentynine" className="flex direct-column">
                  25 - 29
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="age-group" id="thirty-to-thirtyfour" className="checkbox" />
                <label htmlFor="thirty-to-thirtyfour" className="flex direct-column">
                  30- 34
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="age-group" id="thirtyfive-to-thirtyeight" className="checkbox" />
                <label htmlFor="thirtyfive-to-thirtyeight" className="flex direct-column">
                  35 -38
                </label>
              </div>
              <div className="options-row flex gap1">
                <input type="checkbox" name="age-group" id="fourty-plus" className="checkbox" />
                <label htmlFor="fourty-plus" className="flex direct-column">
                  40+
                </label>
              </div>
              <button className="select-all-btn">Select all</button>
            </div>

            <div className="publish-date">
              <h3 className="head-text">Publish on or after</h3>

              <input type="text" placeholder="Select Date" name="publish-date" id="publish-date" className="flex1 select-publish-date-input" onFocus={(e) => e.target.type = "date"} onBlur={(e) => e.target.type = "text"} />
            </div>

            <ButtonFooter />
          </div>
        </div>
      </div>
    </div>
  )
}