

import "./about-team-card.css"

function AboutTeamCard({ image, name, post }) {
  return (
    <div className="about-team-card-container">
      <div className="team-member-avatar">
        <img src={image} alt="name" />
      </div>
      <h3 className="team-member-name">{name}</h3>
      <p className="team-member-post">{post}</p>
    </div>
  )
}

export default AboutTeamCard