export const initialActivities = [
    {
        platform: "Mavin Records",
        activity: "Posted a new opportunity",
        time: "5 min ago"
    },
    {
        platform: "NotjustOk",
        activity: "Your song is live on NotjustOk",
        time: "yesterday"
    },
    {
        platform: "Your first campaign",
        activity: "Your first campaign is live",
        time: "10/02/2023"
    },
    {
        platform: "Flymood",
        activity: "Posted a new opportunity",
        time: "20 min ago"
    },
    {
        platform: "Mavin Records",
        activity: "Posted a new opportunity",
        time: "2 hrs. ago"
    },
]