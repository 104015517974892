const initialCampaigns = [
  {
    campaignId: 'NB00123',
    campaignName: 'Electricfy',
    campaignType: 'Blog',
    dateCreated: '20-10-2023',
    amountSpent: 200000,
    status: true,
    numberOfPlatforms: 3,
    platforms: [
      {
        platformName: 'Naijaloaded',
        datePublished: '10-05-2023',
        views: 500000,
        downloads: '500',
      },
      {
        platformName: 'TechBlogs',
        datePublished: '12-05-2023',
        views: 300000,
        downloads: '200',
      },
      {
        platformName: 'SocialBuzz',
        datePublished: '15-05-2023',
        views: 700000,
        downloads: '800',
      },
    ],
  },
  {
    campaignId: 'NB00124',
    campaignName: 'TechBuzz',
    campaignType: 'Social Media',
    dateCreated: '15-09-2023',
    amountSpent: 150000,
    status: false,
    numberOfPlatforms: 2,
    platforms: [
      {
        platformName: 'Twitter',
        datePublished: '16-09-2023',
        views: 300000,
        downloads: 'N/A',
      },
      {
        platformName: 'Instagram',
        datePublished: '18-09-2023',
        views: 500000,
        downloads: 'N/A',
      },
    ],
  },
  {
    campaignId: 'NB00125',
    campaignName: 'GadgetPromo',
    campaignType: 'Email Marketing',
    dateCreated: '05-08-2023',
    amountSpent: 120000,
    status: true,
    numberOfPlatforms: 1,
    platforms: [
      {
        platformName: 'EmailNewsletter',
        datePublished: '07-08-2023',
        views: 200000,
        downloads: 'N/A',
      },
    ],
  },
  {
    campaignId: 'NB00126',
    campaignName: 'FashionFest',
    campaignType: 'Influencer Marketing',
    dateCreated: '12-07-2023',
    amountSpent: 180000,
    status: false,
    numberOfPlatforms: 2,
    platforms: [
      {
        platformName: 'InfluencerBlog',
        datePublished: '15-07-2023',
        views: 400000,
        downloads: 'N/A',
      },
      {
        platformName: 'InstagramInfluencers',
        datePublished: '18-07-2023',
        views: 300000,
        downloads: 'N/A',
      },
    ],
  },
  {
    campaignId: 'NB00127',
    campaignName: 'FoodFiesta',
    campaignType: 'Content Marketing',
    dateCreated: '28-06-2023',
    amountSpent: 250000,
    status: true,
    numberOfPlatforms: 2,
    platforms: [
      {
        platformName: 'FoodBlogs',
        datePublished: '01-07-2023',
        views: 600000,
        downloads: 'N/A',
      },
      {
        platformName: 'YouTubeChannels',
        datePublished: '03-07-2023',
        views: 700000,
        downloads: 'N/A',
      },
    ],
  },
  {
    campaignId: 'NB00128',
    campaignName: 'TravelTrails',
    campaignType: 'Video Marketing',
    dateCreated: '10-05-2023',
    amountSpent: 300000,
    status: false,
    numberOfPlatforms: 2,
    platforms: [
      {
        platformName: 'YouTubeChannels',
        datePublished: '12-05-2023',
        views: 400000,
        downloads: 'N/A',
      },
      {
        platformName: 'TravelBlogs',
        datePublished: '15-05-2023',
        views: 350000,
        downloads: 'N/A',
      },
    ],
  },
  {
    campaignId: 'NB00129',
    campaignName: 'HealthHub',
    campaignType: 'Search Engine Marketing',
    dateCreated: '02-04-2023',
    amountSpent: 180000,
    status: true,
    numberOfPlatforms: 1,
    platforms: [
      {
        platformName: 'GoogleAds',
        datePublished: '05-04-2023',
        views: 800000,
        downloads: 'N/A',
      },
    ],
  },
];

export const getCampaign = (id) => {
  return initialCampaigns.filter(c => c.campaignId === id)[0] || null;
}

export default initialCampaigns;
