import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoPeople } from "react-icons/go";
import { BiDollarCircle } from "react-icons/bi";
import { GiMegaphone } from "react-icons/gi";
import "./musician-dashboard-overview.css";
import Header from "../../../components/header/Header";
// import Sidenav from "../../../components/sidenav/Sidenav";
import OverviewCard from "../../../components/overview-card/OverviewCard";
import { useEffect } from "react";
// import initialCampaigns from "../../../assets/utils/campaigns";
import ActivityPreview from "../../../components/activity-preview/ActivityPreview";
import DashboardCampaignPreview from "../../../components/dashboard-campaign-preview/DashboardCampaignPreview";
import { initialActivities } from "../../../assets/utils/activities";
import BloggerNav from "../../../components/blogger-nav/BloggerNav";
import { getCampaigns } from "../../../apis/musician";

function MusicianDashboardOverview() {
  const [showSideNav, setShowSideNav] = useState(true);
  const toggleShowSideNav = () => setShowSideNav((a) => !a);
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState(null);
  const [activities, setActivities] = useState(null);

  useEffect(() => {
    getCampaigns()
      .then((response) => {
        setCampaigns(response.data);
        // const stats = response.stats
      })
      .catch((error) => {
        // do something
      });

    setActivities((prev) => initialActivities.slice(0, 3));
  }, []);

  return (
    <div className="overview">
      <BloggerNav showSideNav={showSideNav} />
      <main className="overview-main">
        <Header
          showSideNav={showSideNav}
          toggleShowSideNav={toggleShowSideNav}
          pageHeading={"Overview"}
        />

        <div className="body">
          <div className="overview-cards-container">
            <OverviewCard
              icon={<GoPeople />}
              heading={"ALL CAMPAIGNS"}
              figure={campaigns?.length || "0"}
            />
            <OverviewCard icon={<GiMegaphone />} heading={"TOTAL SPENT"} />
            <OverviewCard icon={<BiDollarCircle />} heading={"IMPRESSIONS"} />
          </div>

          <div className="campaign-activity-card-container">
            <div className="campaign-card">
              <div className="header flex justify-between items-center">
                <h3 className="head-text">Published Campaigns </h3>

                <div className="action">
                  <Link to="/start-campaign" className="view-all-link">
                    New Campaign
                  </Link>

                  <Link to="/campaigns" className="view-all-link">
                    View All
                  </Link>
                </div>
              </div>
              {campaigns?.length === 0 ? (
                <div className="campaign-body">
                  <div>
                    <div>
                      <p className="bigger-text">No Campaign Yet</p>
                      <p>Your Campaigns will be displayed here</p>
                    </div>

                    <button
                      className="start-campaign-btn"
                      onClick={() => navigate("/start-campaign")}
                    >
                      Start a campaign &nbsp; &rarr;
                    </button>
                  </div>
                </div>
              ) : (
                <DashboardCampaignPreview campaigns={campaigns} />
              )}
            </div>

            <div className="activity-card">
              <h3 className="heading">Activities</h3>
              {activities?.length === 0 ? (
                <div className="activity-body">
                  <div>
                    <p className="bigger-text">No Activities Yet</p>
                    <p>Your Activities will be displayed here</p>
                  </div>
                </div>
              ) : (
                <div className="activities-preview">
                  {activities?.map((act) => (
                    <ActivityPreview activity={act} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default MusicianDashboardOverview;
