import React, { useContext, useEffect, useState } from "react";

import "./choose-platform.css"
import PlatformCard from "../../../../components/platform-card/PlatformCard";
import { AiOutlineSearch } from "react-icons/ai";
import { getPlatforms } from "../../../../assets/utils/platforms";
import { CreateCampaignContext } from "../../../../context/Context";
import { getCampaignBloggers } from "../../../../apis/musician";
import { Bars, Circles } from "react-loading-icons";

export default function ChoosePlatform({searchStr}) {
  const [platforms, setPlatforms] = useState([]);
  // const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const { state, dispatch } = useContext(CreateCampaignContext);
  const { selectedPlatforms } = state;
  const updatePlatform = (id) => {
    const updatedPlatforms = [...platforms]
    const updateSelectedPlatform = updatedPlatforms.map(platform => {
      if (platform.id === id) platform.selected = !!!platform.selected
      return platform
    }).filter(plat => plat.selected);
    dispatch({type:"selectPlatform",payload: updateSelectedPlatform})
    setPlatforms(updatedPlatforms);
  }
  const searchPlatform =async (str) => {
    const data = await checkSelected();
    const searchPlatform = data.filter(platform => platform?.name.toLowerCase().includes(str.toLowerCase()));
    setPlatforms(searchPlatform);
  }
  useEffect(() => {
    checkSelected();
  },[])
  const fetchPlatforms = async () => {
    dispatch({ type: "loading" });
    try {
      const res = await getCampaignBloggers(state.campaignDetails.slug);
      return res.data;
    } catch (error) {
      dispatch({ type: "error", payload: {errors: error.errors } })
    } finally {
      dispatch({type: "endLoading"})
    }
  }
  const checkSelected = async () => {
    let data = await fetchPlatforms();
    for (let i = 0; i < selectedPlatforms.length; i++){
      for (let j = 0; j < data.length; j++){
        if(data[j].id === selectedPlatforms[i].id) data[j].selected = true;
      }
    }
    setPlatforms(data);
    return data
  }
  useEffect(() => {
    searchPlatform(searchStr)
  },[searchStr])
  return (
    <div className="choose-upload-platform">
      <div className="choose-platforms-container">
        {state.loading ? <Bars strokeWidth={.1} fill="#FF614D"/> :

        platforms.length > 0 && platforms.map(platform =>
          <PlatformCard
            platform={platform}
            updatePlatform={() => updatePlatform(platform.id)}
          />
        )}
      </div>
    </div>
  )
}