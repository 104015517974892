import React from "react";

import { RxBell } from "react-icons/rx"
import { PiCaretDownBold } from "react-icons/pi"

import { GiHamburgerMenu } from "react-icons/gi"
import { AiOutlineClose } from "react-icons/ai"

// import wale from "../../assets/images/walee.png"

import "./payment-header.css";

function PaymentHeader({ showSideNav, toggleShowSideNav, pageHeading, avatar }) {
  return (
    <nav className="blogger-payment-header">
      <div className="container flex justify-between items-center">
        <span className="mobile-menu">
          <button className={`close ${showSideNav ? "show" : "hide"}`} onClick={toggleShowSideNav}>
            <AiOutlineClose style={{ fontSize: "2em" }} />
          </button>
          <button className={`open ${showSideNav ? "hide" : "show"}`} onClick={toggleShowSideNav}>
            <GiHamburgerMenu style={{ fontSize: "2em" }} />
          </button>
        </span>

        <div className="blogger-profile flex items-center gap">
          <div className="header-btn-container flex items-center gap1">
            <button className="revenue-btn header-btn active">Revenue</button>
            <button className="payment-history-btn header-btn">Payment History</button>
            <button className="payment-method-btn header-btn">Payment Method</button>
          </div>

          <span className="bell">
            <RxBell />
          </span>

          {/* <img src={wale} alt="blogger" className="blogger-avatar" /> */}
          <div className="blogger-avatar" style={{ backgroundImage: `url(${avatar})` }}>D</div>

          <h4 className="blogger-name">Day Wale</h4>

          <span className="caret-down">
            <PiCaretDownBold />
          </span>
        </div>
      </div>
    </nav>
  )
}

export default PaymentHeader;