import React, { useReducer } from 'react'
import { BloggerContext } from '../../context/Context';
import { bloggerReducer } from './bloggerReducer';

function BloggerProvider({children}) {
    const initialState = { loading: false, success: false, error: null, user: null,blogger: null, message: "" };
    const [state, dispatch] = useReducer(bloggerReducer, initialState);
    return (
        <BloggerContext.Provider value={{ state, dispatch }}>
            {children}
        </BloggerContext.Provider> 
  )
}

export default BloggerProvider