import React from 'react';

import "./create-influencer-campaign.css";

import logo from "../../../assets/images/logo-l.png"
import Header from "../../../components/header/Header";
// import ChooseInfluencerGoal from "./choose-influencer-goal/ChooseInfluencerGoal";
import ContentToShare from "./content-to-share/ContentToShare";

export default function CreateInfluencerCampaign() {
  return (
    <div className="create-influencer-campaign">
      <Header pageHeading={<img src={logo} alt="logo" />} />

      <div className="create-influencer-campaign-body">
        <ContentToShare />
      </div>

      <div className="footer-next-btn-container">
        <button className="next-btn">Next</button>
      </div>
    </div>
  )
}