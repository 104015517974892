import React from 'react';
import { FaFacebook, FaTiktok, FaTwitter } from 'react-icons/fa6';
import { RiInstagramFill } from "react-icons/ri";
import "./account-setting-edit-password.css";

function AccountSettingEditPassord() {
  return (
      <>
        <div className="account-setting-edit-password">
            <form action="">
                <div className="form-field">
                    <label htmlFor="opassowrd">Current Password</label>
                    <input type="password" name="opassowrd" placeholder='***************' />
                </div>
                <div className="form-field">
                    <label htmlFor="npassowrd">New Password</label>
                    <input type="password" name="npassowrd" placeholder='***************' />
                </div>
                <div className="form-field">
                    <label htmlFor="cpassowrd">Confirm Password</label>
                    <input type="password" name="cpassowrd" placeholder='***************' />
                </div>
                <div className="line-space"></div>
                <div className="form-line"></div>
                <button type='submit'>Update</button>
            </form>
        </div>
        <div className="account-setting-socials">
            <h3>Social Account</h3>
            <div className="line-space"></div>
            <div className="form-line"></div>
            <div className="social-account-details">
                <div className="social-acct-name-link">
                    <p><FaFacebook /></p>
                    <div className="">
                        <p>Facebook account</p>
                        <small><a href="https://www.facebook.com/day-wale" target='_blank'>www.facebook.com/day-wale</a></small>
                    </div>
                </div>
                <button>Disconnect</button>
            </div>
            <div className="social-account-details">
                <div className="social-acct-name-link">
                    <p><FaTwitter /></p>
                    <div className="">
                        <p>Twitter account</p>
                        <small><a href="https://www.facebook.com/day-wale" target='_blank'>www.facebook.com/day-wale</a></small>
                    </div>
                </div>
                <button>Disconnect</button>
            </div>
            <div className="social-account-details">
                <div className="social-acct-name-link">
                    <p><RiInstagramFill /></p>
                    <div className="">
                        <p>Instagram account</p>
                        <small><a href="#">not connected</a></small>
                    </div>
                </div>
                <button className='connect'>connect</button>
            </div>
            <div className="social-account-details">
                <div className="social-acct-name-link">
                    <p><FaTiktok /></p>
                    <div className="">
                        <p>Tiktok account</p>
                        <small><a href="#">not connected</a></small>
                    </div>
                </div>
                <button className='connect'>connect</button>
            </div> 
        </div>
      </>
  )
}

export default AccountSettingEditPassord