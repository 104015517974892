import React from 'react'
import { IoSearchSharp } from "react-icons/io5";

import "./published-content.css"

export default function PublishedContent() {
    return (
        <div className="published-content">
            <div className="head">Published Content</div>

            <div className="table-body">
                <div className="table-heading">
                    <div className="search-bar-container flex gap1 items-center">
                        <span className="lens-container"><IoSearchSharp style={{fontSize: "20px", lineHeight: "0px"}}/></span>
                        <input type="text" name="published-content" id="published-content" placeholder="What are you looking for ?"/>
                    </div>
                </div>

                <div className="published-content-table-container">
                    <table className="published-content-table">
                        <tbody>
                            <tr>
                                <th>POST ID</th>
                                <th>TYPE</th>
                                <th>CAMPAIGN TYPE </th>
                                <th>STATUS</th>
                                <th>PUBLISHED DATE</th>
                                <th>DETAILS</th>
                            </tr>
                            <tr>
                                <td>electrifier</td>
                                <td className="type">MUSIC</td>
                                <td>blog campaign</td>
                                <td className="status">WAITING APPROVAL</td>
                                <td>05-04-2023</td>
                                <td className="details">
                                    <button className="table-view-edit-btn">View/Edit</button>
                                </td>
                            </tr>
                            <tr>
                                <td>electrifier</td>
                                <td className="type">PRESS RELEASE</td>
                                <td>blog campaign</td>
                                <td className="status">ERROR</td>
                                <td>05-04-2023</td>
                                <td className="details">
                                    <button className="table-view-edit-btn">View/Edit</button>
                                </td>
                            </tr>
                            <tr>
                                <td>electrifier</td>
                                <td className="type">LYRICS</td>
                                <td>blog campaign</td>
                                <td className="status">PUBLISHED</td>
                                <td>05-04-2023</td>
                                <td className="details">
                                    <button className="table-view-edit-btn">View/Edit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}