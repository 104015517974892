export const createCampaignReducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return { ...state, isError: false, loading: true, errors: null, message: "" }
    case "endLoading":
      return {...state, loading: false}
    case "error":
      return {...state, isError: true, loading: false, errors: action.payload?.errors, message: action.payload.message}
    case "pageNumberChanged":
      return { ...state, isError: false, loading: false, pageNumber: action.payload, errors: null, message: "" };
    case "campaignType":
      return { ...state, campaignDetails: { ...state.campaignDetails, "campaign_type": action.payload }, isError: false, loading: false, errors: null, message: "" }
    case "selectPlatform":
      return { ...state, selectedPlatforms: action.payload, isError: false, loading: false, errors: null, message: "" }
    case "contentType":
      return { ...state, campaignDetails: { ...state.campaignDetails, "content_type": action.payload }, isError: false, loading: false, errors: null, message: "" }
    case "campaignDetails":
      return { ...state, campaignDetails: { ...state.campaignDetails, ...action.payload }, isError: false, loading: false, errors: null, message: "" }
    case "updateCampaignDetails":
      return { ...state, campaignDetails: action.payload, isError: false, loading: false, errors: null, message: "" }
    case "setDraft":
      return { ...state, pageNumber: action.payload.pageNumber, isDraft: true, isError: false, loading: false, errors: null, message: "" }
    case "fileupload":
      return {...state, isAudioFile: action.payload.isAudioFile, isCoverArt: action.payload.isCoverArt}
    default:
      return state;
  }
}