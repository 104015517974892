import React from 'react'
import {FaTiktok} from "react-icons/fa";
import {AiFillInstagram,AiFillLinkedin} from "react-icons/ai";
import {BsTwitter} from "react-icons/bs";
import {BiLogoFacebook} from "react-icons/bi";
import logo from "../../assets/images/logo.png";
import "./footer-dashboard.css";

function FooterDashboard() {
  return (
    <footer className='dashboard-footer'>
        <div className="footer-container">
            <div className="footer-logo">
                <img src={logo} alt="Newbanger logo" />
            </div>
            <ul className="explore-list">
                <h3>Explore</h3>
                <li>About Us</li>
                <li>How it works</li>
                <li>Blog</li>
            </ul>

            <ul className="explore-list">
                <h3>FAQ</h3>
                <li>Contact Us</li>
            </ul>

            <ul className="explore-list">
                <h3>Privacy Policy</h3>
                <li>Musician T&C</li>
                <li>Curator T&C</li>
            </ul>
        </div>
        <div className="footer-note">
            <p>Copyright 2023 Newbanger Digital Limited</p>
            <div className="social-media-icons">
                <span><FaTiktok/></span>
                <span><AiFillInstagram/></span>
                <span><BsTwitter/></span>
                <span><AiFillLinkedin/></span>
                <span><BiLogoFacebook/></span>
            </div>
        </div>
    </footer>
  )
}

export default FooterDashboard