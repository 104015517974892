import React, { useContext, useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";

import "./finalize.css"

import AddedPlatformCard from "../../../../components/added-platform-card/AddedPlatformCard";
import { AuthContext, CreateCampaignContext } from "../../../../context/Context";
import { storeCampaignBloggers } from "../../../../apis/musician";
import { centerErrorAlert } from "../../../../utils/alert";
import { SpinningCircles } from "react-loading-icons";


export default function Finalize() {
  let navigate = useNavigate();
  const { slug } = useParams();
  const { state, dispatch } = useContext(CreateCampaignContext);
  const userDetails = useContext(AuthContext)
  const { selectedPlatforms } = state;
  const removePlatform = (platform) => {
    let updatedPlatform = selectedPlatforms.filter(plat => plat.id != platform.id);
    dispatch({ type: "selectPlatform", payload: updatedPlatform });
  }
  console.log(selectedPlatforms)
  let total = () => state?.selectedPlatforms?.reduce((a,b) => a + +b.charge_amount,0) || 0
  const addSelectedBloggers = async () => {
    dispatch({type:"loading"})
    try {
      const res = await storeCampaignBloggers(slug, {"blogger_ids":selectedPlatforms.map(plat => plat.id), "estimated_amount":total()})
      console.log(res)
      navigate(`/payment/${slug}?email=${userDetails.state.user.email}&amount=${total()}`)
    } catch (error) {
      centerErrorAlert("unable to add bloggers, please try again!")
    } finally {
      dispatch({type: "endLoading"})
    }
    
  }

  return (
    <div className="finalize-create-blog-campaign">
      <div className="top-part-summary">
        <div className="finalize-header flex justify-between gap1 items-center">
          <div className="text flex gap1 direct-column gap">
            <h3>Below are the added platforms</h3>
            <h4>You can remove or add more platforms</h4>
          </div>

          <button className="add-more-platforms-btn" onClick={() => dispatch({type: "pageNumberChanged", payload: --state.pageNumber})}>Add More</button>
        </div>

        <div className="chosen-platforms-container">
          {selectedPlatforms.map(platform => 
            <AddedPlatformCard
              platform={platform}
              removePlatform = {() => removePlatform(platform)}
            />
          )}
        </div>

      </div>

      <div className="bottom-part-payment">
        <h2 className="heading">ADD PAYMENT AND FINALIZE</h2>

        <div className="summary-container">
          <h2 className="head-desc">Summary</h2>
          <div className="summary-body">
            <table className="summary-table">
              <tbody>
                <tr>
                  <td>Campaign Type</td>
                  <td>{state.campaignDetails.campaign_type}</td>
                </tr>
                <tr>
                  <td>Content Type</td>
                  <td>{state.campaignDetails.content_type}</td>
                </tr>
                <tr>
                  <td>Total Platforms</td>
                  <td>{ state.selectedPlatforms.length}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>{total()}</td>
                </tr>
              </tbody>
            </table>
            {state.loading ?
              <button className="launch-campaign-btn">
                <SpinningCircles strokeWidth={.1}/>
              </button> :
              <button className="launch-campaign-btn" onClick={addSelectedBloggers}>
                Launch Campaign &nbsp;&rarr;
              </button>
            }
          </div>

        </div>
      </div>
    </div>
  )
}